import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Checkbox, FormControlLabel, Grid, styled, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Redirect } from 'react-router-dom';
import { OnboardingLayout } from '../components/OnboardingLayout';
import { ChangeEvent } from 'react';
import { removeConsentLocalStorage, setConsentLocalStorage } from '#/helpers/localConsent';
import { GoogleLogin } from '../components/GoogleLogin';

const LoginButtonContainer = styled(Grid)(({ theme }) => ({
  gap: theme.spacing(1),
  flexDirection: 'column',
  alignItems: 'center',
}));

declare global {
  interface Window {
    plausible: (name: string) => void;
  }
}

window.plausible = window.plausible || (() => void 0);

export const Login = () => {
  const { loginWithPopup, isAuthenticated } = useAuth0();
  const signUp = (options?: { google: boolean }) => {
    loginWithPopup({
      screen_hint: 'signup',
      ...(options?.google ? { connection: 'google-oauth2' } : {}),
    });

    window.plausible('Signup');
  };

  const login = () => {
    loginWithPopup();
    window.plausible('Login');
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  const handleConsent = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setConsentLocalStorage(new Date().toISOString());
    } else {
      removeConsentLocalStorage();
    }
  };

  const sidebar = (
    <>
      <img
        style={{ margin: 'auto' }}
        height="70px"
        width="70px"
        src="/pollpilot-logo-dark.svg"
        alt="PollPilot"
      />
      <Grid sx={{ textAlign: { lg: 'left', md: 'center' } }}>
        <Typography variant="h1">PollPilot</Typography>
        <Typography variant="subtitle1">The easiest way to collect feedback.</Typography>
      </Grid>
    </>
  );

  return (
    <OnboardingLayout sidebar={sidebar}>
      <Box
        sx={{ m: 'auto', textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <div>
          <Typography variant="h1" gutterBottom>
            Welcome 👋
          </Typography>
          <Typography variant="h3">Click here to sign up</Typography>
        </div>
        <ArrowDownwardIcon sx={{ m: 'auto' }} />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Yes, I want to receive emails about new features and updates."
          onChange={handleConsent}
        />
        <LoginButtonContainer container>
          <GoogleLogin handleClick={() => signUp({ google: true })} />
          <Typography variant="h6">or</Typography>
          <Button onClick={() => signUp()} variant="outlined">
            Sign up via email
          </Button>
        </LoginButtonContainer>
        <div>
          <Typography variant="body2" component={Button} sx={{ mt: 1 }} onClick={() => login()}>
            Sign in with your existing account.
          </Typography>
        </div>
      </Box>
    </OnboardingLayout>
  );
};
