import { IWidget } from '#/dto/IWidget';
import { Card, CardActionArea, styled, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';

interface EmbedCodeProps {
  widget: IWidget;
}

const StyledCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.palette.background.default};
  width: fit-content;
  overflow: auto;
`;

const CodeBlock = styled('code')``;

const CustomCardActionArea = styled(CardActionArea)`
  border-radius: 5px;
  padding: ${({ theme }) => theme.spacing(2)};
  &:hover {
    background-color: rgba(${({ theme }) => theme.palette.background.default}, 0.3);
  }
`;

export const EmbedCode = ({ widget }: EmbedCodeProps) => {
  if (!widget?.shortId) return null;
  const code = `<script async defer src="${process.env.HOME_URL}/client.js" id="${widget.shortId}"></script>`;
  const [isCopied, setIsCopied] = useState(false);
  const handleCardClick = () => {
    navigator.clipboard.writeText(code);
    setIsCopied(true);
  };

  return (
    <StyledCard>
      <Typography variant="h2" gutterBottom>
        Embed code
      </Typography>
      <Tooltip title={isCopied ? 'Copied' : 'Click to copy'}>
        <CustomCardActionArea onMouseEnter={() => setIsCopied(false)} onClick={handleCardClick}>
          <CodeBlock>{code}</CodeBlock>
        </CustomCardActionArea>
      </Tooltip>
    </StyledCard>
  );
};
