import api from '#/constants/api';
import { IUser } from '#/dto/IUser';
import { useRequest } from '#/hooks/useRequest';
import { useAuth0 } from '@auth0/auth0-react';
import { ListItem, MenuItem, Select, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface AdminSelectUsersProps {
  currentUser: IUser;
}

const USERID_CLAIM = 'https://pollpilot.io/user_id';

export const AdminSelectUsers = ({ currentUser }: AdminSelectUsersProps) => {
  const { user: authUser } = useAuth0();
  const [allUsers, setAllUsers] = useState<IUser[]>([]);
  const request = useRequest();
  const loadAllUsers = async () => {
    const response = await request<IUser[]>('/users');
    if (response.res?.data) {
      setAllUsers(response.res.data);
    }
  };

  const updateUserId = async (user: IUser, userId: string) => {
    if (!user?.admin) return;
    const res = await request(api.impersonate(userId), {
      method: 'POST',
    });

    if (res.res) {
      window.location.reload();
    }
  };

  const handleUserUpdate = async (user: IUser) => {
    await updateUserId(currentUser, user.authId);
  };

  useEffect(() => {
    if (currentUser?.admin) {
      loadAllUsers();
    }
  }, [currentUser?.admin]);

  if (!currentUser?.admin) return null;
  return (
    <>
      {currentUser?.impersonationId && currentUser.impersonationId !== authUser.sub && (
        <ListItem>
          <Typography textAlign="center" variant="h2" fontSize={20} sx={{ width: '100%' }}>
            Impersonating!
          </Typography>
        </ListItem>
      )}
      <ListItem>
        <Select sx={{ width: '100%' }} value={currentUser.impersonationId || authUser.sub}>
          {allUsers
            .filter((user) => !!user.authId)
            .map((user) => (
              <MenuItem
                onClick={() => handleUserUpdate(user)}
                key={user.authId}
                value={user.authId}
                selected={user.authId === authUser[USERID_CLAIM]}
              >
                {user.email}
              </MenuItem>
            ))}
        </Select>
      </ListItem>
    </>
  );
};
