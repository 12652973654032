import { IVote } from '#/dto/IVote';

export default {
  widget: (id: string) => `/widgets/${id}`,
  widgets: '/widgets',
  register: '/register',
  vote: (id: string) => `/widgets/${id}/vote`,
  widgetVotes: (widgetId: string) => `/widgets/${widgetId}/votes`,
  widgetAnalytics: (widgetId: string) => `/widgets/${widgetId}/analytics`,
  globalAnalytics: '/votes/analytics',
  email: '/email',
  votesCount: (shortId: string, groupBy: keyof IVote) =>
    `/votes/count?widgetId=${shortId}&groupBy=${groupBy}`,
  votes: '/votes',
  getCreateUser: '/user/create',
  impersonate: (id: string) => `/user/impersonate?userId=${id}`,
  subscribe: '/subscription',
  manageSubscription: '/subscription/manage',
  me: '/me',
};
