import api from '#/constants/api';
import { IVote } from '#/dto/IVote';
import { IWidget } from '#/dto/IWidget';
import { useRequest } from '#/hooks/useRequest';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  styled,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ResponseTable } from '../components/ResponseTable';

const LIMIT = 5;

const CenteredButton = styled(Button)(({}) => ({
  margin: 'auto',
}));

export const AllResponses = ({ widget, all }: { widget?: IWidget; all?: boolean }) => {
  const [votes, setVotes] = useState<IVote[]>();
  const [showLoadMore, setShowLoadMore] = useState(true);
  const skip = useRef(0);
  const [loading, setLoading] = useState(true);
  const request = useRequest();
  const requestVotes = async (currentSkip?: number) => {
    if (!all && !widget?.shortId) return;
    const newSkip = currentSkip ?? skip.current;
    const url = `${
      !all ? api.widgetVotes(widget?.shortId) : api.votes
    }?populateEmails=true&limit=${LIMIT}&skip=${newSkip}&aggregateOptions=true&aggregateShortWidgetIds=true`;
    setLoading(true);
    const { res } = await request<IVote[]>(url);
    setLoading(false);
    if (res?.data?.length) {
      setVotes((prev) => [...(prev?.length ? prev : []), ...res.data]);
      skip.current = newSkip;
      if (res.data.length < LIMIT) {
        setShowLoadMore(false);
      }
    }
  };

  useEffect(() => {
    requestVotes();
  }, [widget]);

  if (!widget?.shortId && !all) return null;

  if (loading && !votes?.length) {
    return <CircularProgress />;
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h2">All responses</Typography>
        <br />
        <ResponseTable responses={votes} widget={widget} />
        <Box mt={2} />
        <Grid container>
          {showLoadMore && (
            <CenteredButton variant="outlined" onClick={() => requestVotes(skip.current + LIMIT)}>
              Load more
            </CenteredButton>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
