import routes from '#/constants/routes';
import { IGlobalAnalytics } from '#/dto/IWidgetAnalytics';
import { Box, Card, CardContent, Link, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { Link as ReactLink } from 'react-router-dom';

const Wrapper = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
}));

const TopPolls = styled('ol')({});

const GlobalAnalyticsWrapper = ({
  className,
  children,
}: {
  className: string;
  children: ReactNode;
}) => {
  return (
    <Card className={className}>
      <Wrapper>{children}</Wrapper>
    </Card>
  );
};

export const GlobalAnalytics = ({
  analytics,
  loading,
  className,
}: {
  analytics: IGlobalAnalytics;
  className?: string;
  loading: boolean;
}) => {
  if (loading) {
    return (
      <GlobalAnalyticsWrapper className={className}>
        <Box sx={{ m: 'auto' }}>
          <Typography variant="h6">Loading recent activity...</Typography>
        </Box>
      </GlobalAnalyticsWrapper>
    );
  }

  return (
    <GlobalAnalyticsWrapper className={className}>
      {!!analytics?.mostPopularPollsToday?.length ? (
        <div>
          <Typography variant="h6">The most popular polls in the last 24 hours were:</Typography>
          <TopPolls>
            {analytics.mostPopularPollsToday.map((widget) => (
              <ReactLink to={routes.widget(widget.widget.shortId)} key={widget._id}>
                <Link component="span">
                  <Typography variant="body1" component="li">
                    {widget.widget?.internalTitle || widget.widget?.title} - {widget.count} votes
                  </Typography>
                </Link>
              </ReactLink>
            ))}
          </TopPolls>
        </div>
      ) : (
        <Typography variant="h6">No polls have been voted in the last 24 hours.</Typography>
      )}
    </GlobalAnalyticsWrapper>
  );
};
