import { RecentResponses } from '#/components/Responses/RecentResponses';
import api from '#/constants/api';
import { IWidget } from '#/dto/IWidget';
import { IGlobalAnalytics } from '#/dto/IWidgetAnalytics';
import { useRequest } from '#/hooks/useRequest';
import { styled, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { GlobalAnalytics } from './GlobalAnalytics/GlobalAnalytics';
import { Summary } from './Summary';
import { WidgetList } from './WidgetList';

const Grid = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'column',
}));

const AnalyticsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
}));

const StyledSummary = styled(Summary)(({ theme }) => ({
  flex: '1 0 auto',
  [theme.breakpoints.down('md')]: {
    flex: '1 0 100%',
  },
}));

const StyledGlobalAnalytics = styled(GlobalAnalytics)(({ theme }) => ({
  flex: '0 0 30%',
  [theme.breakpoints.down('md')]: {
    flex: '1 0 100%',
  },
}));

const useGetWidgets = () => {
  const [widgets, setWidgets] = useState<IWidget[]>([]);
  const request = useRequest();
  const requestWidgets = useCallback(async () => {
    const { res } = await request<IWidget[]>(
      `${api.widgets}?sortByVotes=true&aggregateVotesCount=true&aggregateAverageResult=true`,
    );
    if (res?.data) {
      setWidgets(res.data);
    }
  }, []);

  useEffect(() => {
    requestWidgets();
  }, []);

  return { widgets };
};

const Dashboard = () => {
  const { widgets } = useGetWidgets();
  const [loadingAnalytics, setLoadingAnalytics] = useState(true);
  const [analytics, setAnalytics] = useState<IGlobalAnalytics>();
  const request = useRequest();

  const loadAnalytics = async () => {
    setLoadingAnalytics(true);
    const response = await request<IGlobalAnalytics>(api.globalAnalytics);
    setLoadingAnalytics(false);
    if (response.res?.data) {
      setAnalytics(response.res.data);
    }
  };

  useEffect(() => {
    loadAnalytics();
  }, []);

  return (
    <Grid>
      <AnalyticsContainer>
        <StyledSummary loading={loadingAnalytics} analytics={analytics} />
        <StyledGlobalAnalytics loading={loadingAnalytics} analytics={analytics} />
      </AnalyticsContainer>
      <RecentResponses widgets={widgets} all={true} />
      <div>
        <Typography gutterBottom variant="h2">
          Your widgets
        </Typography>
        <WidgetList widgets={widgets} />
      </div>
    </Grid>
  );
};

export default Dashboard;
