import api from '#/constants/api';
import { IVote } from '#/dto/IVote';
import { IWidget } from '#/dto/IWidget';
import { useRequest } from '#/hooks/useRequest';
import { RequireAtLeastOne } from '#/helpers/typescript';
import { Box, Card, CardContent, styled, Typography } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { ResponseTable } from '../components/ResponseTable';

interface RecentResponsesProps {
  widget: IWidget;
  widgets?: IWidget[];
  all?: boolean;
}

type IRecentResponsesProps = RequireAtLeastOne<RecentResponsesProps, 'widget' | 'all' | 'widgets'>;

const Wrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          Recent responses
        </Typography>
        <ResponseWrapper>{children}</ResponseWrapper>
      </CardContent>
    </Card>
  );
};

const ResponseWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  justifyContent: 'flex-start',
}));

export const RecentResponses = ({ widget, widgets, all }: IRecentResponsesProps) => {
  const [loading, setLoading] = useState(true);
  const [recentResponses, setRecentResponses] = useState<IVote[]>([]);
  const request = useRequest();
  const loadRecentResponses = async (shortId?: string) => {
    setLoading(true);
    const { res } = await request<IVote[]>(
      `${all ? api.votes : api.widgetVotes(shortId)}?limit=5${
        all ? '&aggregateShortWidgetIds=true' : ''
      }`,
    );
    setLoading(false);
    if (res?.data) {
      setRecentResponses(res.data);
    }
  };

  useEffect(() => {
    if (widget?.shortId) {
      loadRecentResponses(widget.shortId);
    }
  }, [widget?.shortId]);

  useEffect(() => {
    if (all) {
      loadRecentResponses();
    }
  }, [all]);

  if (loading || !recentResponses?.length)
    return (
      <Wrapper>
        <Box sx={{ minHeight: '300px' }}>
          <Typography variant="h6">
            {loading ? 'Loading recent responses... ' : 'There are no recent responses yet.'}
          </Typography>
        </Box>
      </Wrapper>
    );

  if ((!widget?.additionalFeedback?.active && !all) || !recentResponses?.length) return null;

  return (
    <Wrapper>
      <ResponseTable widgets={widgets} responses={recentResponses} widget={widget} />
    </Wrapper>
  );
};
