import api from '#/constants/api';
import { IOption, IWidget } from '#/dto/IWidget';
import { AnalyticsTimePeriod, IWidgetAnalytics } from '#/dto/IWidgetAnalytics';
import { useRequest } from '#/hooks/useRequest';
import { RequireAtLeastOne } from '#/helpers/typescript';
import { Card, styled, Typography, useTheme } from '@mui/material';
import { ChartData, ChartOptions } from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

const StyledCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.palette.background.default};
  width: 300px;
  height: 200px;
`;

interface AnalyticsSummaryBase {
  widget: IWidget;
  period: AnalyticsTimePeriod | null;
  path: string;
  pageTitle?: string;
  className?: string;
}

type AnalyticsSummaryProps = RequireAtLeastOne<AnalyticsSummaryBase, 'period' | 'path'>;

const getPercentageByOption = (option: IOption, analytics: IWidgetAnalytics) => {
  return Math.floor(
    ((analytics?.results.find((res) => res.optionId === option.id)?.count || 0) /
      (analytics?.voteCount || 1)) *
      100,
  );
};

export const AnalyticsSummary = ({
  widget,
  period,
  className,
  path,
  pageTitle,
}: AnalyticsSummaryProps) => {
  const [analytics, setAnalytics] = useState<IWidgetAnalytics>();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const request = useRequest();

  const loadAnalytics = async () => {
    setLoading(true);
    const { res } = await request<IWidgetAnalytics>(
      `${api.widgetAnalytics(widget?.shortId)}?period=${period ?? ''}&path=${path ? path : ''}`,
    );
    setLoading(false);
    if (res?.data) {
      setAnalytics(res.data);
    }
  };

  useEffect(() => {
    if (widget?.shortId) {
      loadAnalytics();
    }
  }, [widget, period]);

  const barData: ChartData<'bar'> = {
    labels: widget.options.map((option) => option.name?.substring(0, 10)),
    datasets: [
      {
        label: 'Votes',
        data: widget.options.map((option) => getPercentageByOption(option, analytics)),
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
      },
    ],
  };

  const barOptions: ChartOptions<'bar'> = {
    color: theme.palette.text.primary,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (item) => `${item.raw}%`,
        },
      },
    },
    scales: {
      xAxes: {
        ticks: {
          stepSize: 1,
          color: theme.palette.text.primary,
          callback: (value: number) => {
            const option = widget.options.find((option) => option.id === value + 1);
            const optionsLength = widget.options.length;
            if (!option) return value;

            const optionName =
              widget.type === 'reaction' ? `${option.name}` : option.name?.substring(0, 5);
            const percentage =
              optionsLength > 5 ? '' : `${getPercentageByOption(option, analytics)}%`;
            return `${optionName} ${percentage}`;
          },
        },
      },
      yAxes: {
        ticks: {
          display: false,
          color: theme.palette.text.primary,
        },
      },
    },
  };

  let title = (period ? `This ${period} ` : 'All time ') + analytics?.voteCount;
  if (path) {
    title = `${pageTitle || path} — ${analytics?.voteCount} votes`;
  }

  return (
    <StyledCard className={className}>
      <Typography textAlign="center" variant="body2" color="text.secondary" gutterBottom>
        {title}
      </Typography>
      {loading && <div>Loading...</div>}
      {!!analytics && <Bar data={barData} options={barOptions} />}
    </StyledCard>
  );
};
