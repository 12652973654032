import routes from '#/constants/routes';
import { styled } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Type } from './Type/Type';

export const TypeSelectorWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(2)};
`;

interface TypeSelectorProps {
  className?: string;
}

export const TypeSelector = ({ className }: TypeSelectorProps) => {
  const history = useHistory();
  return (
    <TypeSelectorWrapper className={className}>
      <Type name="poll" onClick={() => history.push(routes.createWidgetTypeSelected('poll'))} />
      <Type
        name="reaction"
        onClick={() => history.push(routes.createWidgetTypeSelected('reaction'))}
      />
    </TypeSelectorWrapper>
  );
};
