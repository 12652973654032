import { IOption, IReactionPreset } from '#/dto/IWidget';

export const REACTION_DEFAULT_OPTIONS: {
  id: number;
  name: string;
  options: IOption[];
  presetName: IReactionPreset;
}[] = [
  {
    id: 0,
    name: 'Like / Dislike',
    presetName: 'like-dislike',
    options: [
      {
        id: 1,
        name: '👍',
      },
      {
        id: 2,
        name: '👎',
      },
    ],
  },
  {
    id: 1,
    name: 'Like / Neutral / Dislike',
    presetName: 'like-neutral-dislike',
    options: [
      {
        id: 1,
        name: '👍',
      },
      {
        id: 2,
        name: '😐',
      },
      {
        id: 3,
        name: '👎',
      },
    ],
  },
  {
    id: 2,
    name: '5 Emotions',
    presetName: '5-emotions',
    options: [
      {
        id: 1,
        name: '😡',
      },
      {
        id: 2,
        name: '😟',
      },
      {
        id: 3,
        name: '😐',
      },
      {
        id: 4,
        name: '😄',
      },
      {
        id: 5,
        name: '😍',
      },
    ],
  },
  {
    id: 3,
    name: 'Custom',
    presetName: 'custom',
    options: [],
  },
];
