import * as React from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getDesignTokens } from './theme';

type ThemeMode = 'light' | 'dark';

const ThemeContext = React.createContext<{
  theme: ThemeMode;
  setTheme: (theme: ThemeMode) => void;
}>({
  theme: null,
  setTheme: () => {
    throw new Error('setTheme() is not implemented.');
  },
});

const getLocalMode = () => localStorage.getItem('dark-mode') as ThemeMode;

export function NewThemeProvider(props: { children: React.ReactNode }) {
  const { children } = props;
  const localMode = React.useMemo(() => getLocalMode(), []);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const preferredMode = prefersDarkMode ? 'dark' : 'light';
  const [mode, setMode] = React.useState<ThemeMode>(localMode ? localMode : preferredMode);
  const theme = createTheme(getDesignTokens(mode));

  React.useEffect(() => {
    if (preferredMode && !localMode) {
      setMode(preferredMode);
    }
  }, [preferredMode]);

  React.useEffect(() => {
    if (mode) {
      localStorage.setItem('dark-mode', mode);
    }
  }, [mode]);

  return (
    <ThemeContext.Provider value={{ theme: mode, setTheme: setMode }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
}

export const useThemeContext = () => React.useContext(ThemeContext);
