import { FullHeightWrapper } from '#/components/FullHeightWrapper';
import GradientText from '#/components/GradientText/GradientText';
import routes from '#/constants/routes';
import { Box, Button, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';

export const TrialFinished = () => {
  return (
    <FullHeightWrapper container>
      <Box textAlign="center" sx={{ m: 'auto', maxWidth: '500px' }}>
        <Typography color="primary" variant="body1">
          YOUR TRIAL HAS FINISHED
        </Typography>
        <Typography variant="h1">
          Please select a plan on the <GradientText>subscription page</GradientText> to continue
          using the app.
        </Typography>
        <Box sx={{ m: 'auto', mt: 4, mb: 3 }}>
          <Button component={Link} variant="contained" to={routes.subscriptionPlans}>
            Go to subscription page
          </Button>
        </Box>
        <Typography variant="body1" color="text.secondary">
          Already have a valid subscription? Changes may take some time to take effect.{' '}
          <MuiLink href={`${window.location.origin}${routes.dashboard}`}>Click here</MuiLink> to
          check again.
        </Typography>
      </Box>
    </FullHeightWrapper>
  );
};
