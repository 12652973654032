import routes from '#/constants/routes';
import { IWidget } from '#/dto/IWidget';
import AddIcon from '@mui/icons-material/Add';
import { CardContent, Card, styled, Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const StyledCard = styled(Card)(({}) => ({
  display: 'flex',
  height: '250px',
}));

const StyledAddIcon = styled(AddIcon)`
  height: 100%;
  width: 100%;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const IconWrapper = styled('div')`
  margin: auto;
  height: 100%;
  width: 100%;
`;

interface WidgetListItemProps {
  isEmpty?: boolean;
  widget?: IWidget;
  className?: string;
}

const getClosestOption = (id: number, widget: IWidget) => {
  const option = widget.options.find((option) => option.id === Math.floor(id));
  return option;
};

export const WidgetListItem = ({ isEmpty, widget, className }: WidgetListItemProps) => {
  if (isEmpty) {
    return (
      <StyledCard>
        <StyledLink to={routes.createWidget}>
          <IconWrapper>
            <StyledAddIcon />
          </IconWrapper>
        </StyledLink>
      </StyledCard>
    );
  }

  const closestOption = getClosestOption(widget.averageResult, widget);

  return (
    <StyledLink className={className} to={routes.widget(widget.shortId)}>
      <StyledCard>
        <Grid sx={{ flexDirection: 'column' }} container component={CardContent}>
          <Typography variant="body2" color="text.secondary">
            {widget?.type.toUpperCase()} {widget?.votesCount ? `- ${widget.votesCount} votes` : ''}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {widget?.internalTitle || widget?.title}
          </Typography>
          {!!closestOption && (
            <Typography variant="body1" sx={{ marginTop: 'auto' }} color="text.secondary">
              Average result:{' '}
              {widget.type === 'reaction' ? closestOption.name : widget.averageResult.toFixed(2)}
            </Typography>
          )}
        </Grid>
      </StyledCard>
    </StyledLink>
  );
};
