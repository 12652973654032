import { IconButton, styled, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import Sidebar from './Sidebar';
import MenuIcon from '@mui/icons-material/Menu';
import React, { FC } from 'react';
import { IUser } from '#/dto/IUser';
import { AccessBanner } from './AccessBanner';

const Root = styled('div')({
  display: 'flex',
});

const Content = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  maxWidth: '100%',
  position: 'relative',
}));

const MobileToolbar = styled(Toolbar)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(-2),
}));

const Layout: FC<{ user?: IUser }> = ({ children, user }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Root>
      <Sidebar user={user} handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
      <Content>
        <AccessBanner user={user} />
        {isMobile && (
          <MobileToolbar sx={{ height: 20 }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </MobileToolbar>
        )}
        {children}
      </Content>
    </Root>
  );
};

export default Layout;
