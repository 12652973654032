import { styled } from '@mui/material/styles';
import { IOption, IReactionPreset } from '#/dto/IWidget';
import { ReactionPreset } from './ReactionPreset';
import { useState } from 'react';
import { REACTION_DEFAULT_OPTIONS } from './ReactionOptions.constants';
import { CustomPicker } from './CustomPicker';

const PresetWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(2),
}));

interface ReactionOptionsProps {
  options: IOption[];
  setOptions: (options: IOption[], presetName?: IReactionPreset) => void;
  addOption: (option: IOption, presetName?: IReactionPreset) => void;
  removeOption: (index: number) => void;
}

export const ReactionOptions = ({
  setOptions,
  addOption,
  removeOption,
  options,
}: ReactionOptionsProps) => {
  const [selectedPreset, setSelectedPreset] = useState(0);

  const selectOptions = (presetId: number) => {
    setSelectedPreset(presetId);
    const preset = REACTION_DEFAULT_OPTIONS.find((option) => option.id === presetId);
    setOptions(preset.options, preset.presetName);
  };

  return (
    <>
      <PresetWrapper>
        {REACTION_DEFAULT_OPTIONS.map((preset, index) => (
          <ReactionPreset
            key={`reaction-preset-${index}`}
            options={preset.options}
            name={preset.name}
            checked={selectedPreset === preset.id}
            onClick={() => selectOptions(preset.id)}
          />
        ))}
        {selectedPreset === REACTION_DEFAULT_OPTIONS.length - 1 && (
          <CustomPicker addOption={addOption} removeOption={removeOption} options={options} />
        )}
      </PresetWrapper>
    </>
  );
};
