import { FullHeightWrapper } from '#/components/FullHeightWrapper';
import { Box, styled, Typography } from '@mui/material';
import { TypeSelector } from './TypeSelector';

const CenteredBox = styled(Box)`
  margin: auto;
`;

export const SelectWigetTypePage = () => {
  return (
    <FullHeightWrapper container>
      <CenteredBox>
        <Typography textAlign="center" gutterBottom variant="h1">
          What kind of widget do you want to create? 🤔
        </Typography>
        <Box mb={8} />
        <TypeSelector />
      </CenteredBox>
    </FullHeightWrapper>
  );
};
