import { IAdditonalFeedback } from '#/dto/IWidget';
import { Button, styled, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Title } from '../Poll.styles';

const Wrapper = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({}) => ({
  width: '100%',
}));

export const ExtendedFeedback = ({
  additionalFeedback,
  selectedOptionId,
  handleSubmit,
}: {
  additionalFeedback: IAdditonalFeedback;
  selectedOptionId: number;
  handleSubmit?: (additionalFeedback: string, email: string) => void;
}) => {
  const option = additionalFeedback.options.find((option) => option.optionId === selectedOptionId);
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState('');

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (handleSubmit) {
      handleSubmit(feedback, email);
    }
  };

  return (
    <Wrapper onSubmit={handleFormSubmit}>
      <Title variant="h5" gutterBottom>
        {option?.title}
      </Title>
      <StyledTextField
        required
        rows={4}
        multiline
        placeholder={option?.placeholder}
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
      />
      {additionalFeedback?.email?.active ? (
        <>
          <Typography variant="body1">{additionalFeedback.email.text}</Typography>
          <StyledTextField
            placeholder={additionalFeedback.email.placeholder}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
        </>
      ) : null}
      <Button type="submit" style={{ width: '100%' }} variant="contained" disabled={false}>
        Submit
      </Button>
    </Wrapper>
  );
};
