import { IVote } from '#/dto/IVote';
import { IWidget } from '#/dto/IWidget';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ResponseRow } from './ResponseRow';
import { RequireAtLeastOne } from '#/helpers/typescript';

interface ResponseTableProps {
  responses: IVote[];
  widgets: IWidget[];
  widget: IWidget;
}

export const ResponseTable = ({
  responses,
  widgets,
  widget,
}: RequireAtLeastOne<ResponseTableProps, 'widgets' | 'widget'>) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Option</TableCell>
          <TableCell>Message</TableCell>
          <TableCell>Received</TableCell>
          <TableCell align="right">Details</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {responses?.map((response) => (
          <ResponseRow
            key={`recent-responses-${response._id}`}
            widget={widget ?? widgets?.find((w) => w.shortId === response.widgetShortId)}
            response={response}
            hideLink={!!widget}
          />
        ))}
      </TableBody>
    </Table>
  );
};
