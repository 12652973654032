import { IVote } from '#/dto/IVote';
import { IOption, IWidget } from '#/dto/IWidget';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Collapse,
  IconButton,
  Link,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import routes from '#/constants/routes';

const truncateText = (text: string) => {
  if (text?.length > 5) {
    return `${text.substring(0, 5)}...`;
  }
  return text;
};

const getNameFromOptions = (options: IOption[], id: number) =>
  options?.find((op) => op.id === id)?.name;

export const ResponseRow = ({
  response,
  widget,
  hideLink,
}: {
  response: IVote;
  widget?: IWidget;
  hideLink?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const additionalFeedbackQuestion = widget?.additionalFeedback?.options?.find(
    (option) => option?.optionId === response?.optionId,
  );

  const getOptionName = (option: number, truncate?: boolean) => {
    const optionName = getNameFromOptions(widget?.options, option) || option;
    return truncate ? truncateText(`${optionName}`) : optionName;
  };

  const email = response.email || !!response.emailForm;

  return (
    <>
      <TableRow>
        <TableCell sx={{ maxWidth: '10px' }}>
          {truncateText(getNameFromOptions(response.options, response.optionId)) ||
            getOptionName(response.optionId, true)}
        </TableCell>
        <TableCell>
          <Typography color="text.secondary" variant="body2">
            {widget?.internalTitle || widget?.title} - {additionalFeedbackQuestion?.title}
          </Typography>
          {response.additionalFeedback || <i>No message</i>}
        </TableCell>
        <TableCell>
          {dayjs(response.date).isBefore(dayjs().subtract(35, 'days'))
            ? dayjs(response.date).format('LL')
            : dayjs(response.date).fromNow()}
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={() => setOpen((prevValue) => !prevValue)} title="Expand">
            {!open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ pt: 3, pb: 3 }}>
              <Table>
                {widget?.title && (
                  <TableRow>
                    <TableCell>Question</TableCell>
                    <TableCell>{widget?.title}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>Selected option</TableCell>
                  <TableCell>
                    {getNameFromOptions(response.options, response.optionId) ||
                      getOptionName(response.optionId)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>{dayjs(response.date).format('LL LT')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Page</TableCell>
                  <TableCell>{response.origin + response.path}</TableCell>
                </TableRow>
                {!!response.pageTitle && (
                  <TableRow>
                    <TableCell>Page title</TableCell>
                    <TableCell>{response.pageTitle}</TableCell>
                  </TableRow>
                )}
                {!!email && (
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>
                      <Link href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
                        {email}
                      </Link>
                    </TableCell>
                  </TableRow>
                )}
                {!hideLink && (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <RouterLink
                        style={{ textDecoration: 'none' }}
                        to={routes.widget(widget?.shortId || response?.widgetShortId)}
                      >
                        <Link component="span">Open widget</Link>
                      </RouterLink>
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
