import { IOption } from '#/dto/IWidget';
import { Card, CardActionArea, CardContent, Radio, styled, Typography } from '@mui/material';

const ReactionCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
}));

const DetailsWrapper = styled('div')({
  display: 'flex',
});

const Reaction = styled('div')(({}) => ({
  width: '30px',
  flex: '1 0 auto',
}));

const ContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

interface ReactionPresetProps {
  name: string;
  options: IOption[];
  onClick: VoidFunction;
  checked: boolean;
}

export const ReactionPreset = ({ name, options, onClick, checked }: ReactionPresetProps) => {
  return (
    <ReactionCard>
      <CardActionArea onClick={onClick}>
        <StyledCardContent>
          <Radio onClick={onClick} checked={checked} />
          <ContentWrapper>
            <Typography gutterBottom={!!options.length} style={{ margin: 'auto 0' }} variant="h6">
              {name}
            </Typography>
            {!!options?.length && (
              <DetailsWrapper>
                {options.map((option) => (
                  <Reaction key={`preset-reaction-option-${option.id}`}>{option.name}</Reaction>
                ))}
              </DetailsWrapper>
            )}
          </ContentWrapper>
        </StyledCardContent>
      </CardActionArea>
    </ReactionCard>
  );
};
