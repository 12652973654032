import { IOption } from '#/dto/IWidget';
import { FormControlLabel, Radio, styled, TextField } from '@mui/material';

const OptionTextField = styled(TextField)(({ theme }) => ({
  marginLeft: theme.spacing(4),
}));

const StyledFormControlLabel = styled(FormControlLabel)({
  width: '100%',
});

export const PollOption = ({
  option,
  selected,
  handleOptionClick,
  optionText,
  setOptionText,
  hasVoted,
}: {
  option: IOption;
  selected: boolean;
  handleOptionClick: (id: number) => void;
  optionText: string;
  setOptionText: (text: string) => void;
  hasVoted: boolean;
}) => {
  const showTextInput = option.showTextInput && selected;

  return (
    <>
      <StyledFormControlLabel
        control={
          <Radio
            color="primary"
            checked={selected}
            onChange={() => handleOptionClick(option.id)}
            disabled={hasVoted}
          />
        }
        label={option?.name || `Option ${option.id}`}
      />
      {showTextInput && (
        <OptionTextField
          size="small"
          autoFocus
          value={optionText}
          onChange={(e) => setOptionText(e.target.value)}
        />
      )}
    </>
  );
};
