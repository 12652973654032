export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const stringToEmoji = (option: string) => {
  try {
    return String.fromCodePoint(`0x${option}` as unknown as number);
  } catch (e) {
    return option;
  }
};

export const getWidgetUrl = (shortId: string) =>
  `${process.env.HOME_URL}/widget.html?id=${shortId}`;
