import {
  IAdditionalFeedbackOption,
  IAdditonalFeedback,
  IOption,
  IWidgetCreation,
} from '#/dto/IWidget';
import { ErrorRecord } from '#/hooks/useForm';
import {
  Box,
  Button,
  CardContent,
  FormControlLabel,
  FormGroup,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useRef } from 'react';

const FormContainer = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  flex: '1 1 100%',
  marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const FeedbackInputWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  '> label': {
    margin: 'auto',
    flex: '1 0 100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginBottom: theme.spacing(1),
  },
}));

const excludeAddionalFeedbackOptionsById = (
  additionalFeedback: IAdditonalFeedback,
  id: number,
): IAdditionalFeedbackOption[] => {
  return additionalFeedback?.options.filter((feedback) => feedback.optionId !== id) || [];
};

const getAdditonalFeedbackOptionById = (
  additionalFeedback: IAdditonalFeedback,
  id: number,
): IAdditionalFeedbackOption => {
  return (
    additionalFeedback?.options.find((feedback) => feedback.optionId === id) ||
    ({} as unknown as IAdditionalFeedbackOption)
  );
};

interface IAdditionalFeedbackSectionProps {
  additionalFeedback: IAdditonalFeedback;
  options: IOption[];
  handleChange: (
    key: keyof IWidgetCreation,
    sanitizeFn?: (value: string | boolean) => IAdditonalFeedback,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors: ErrorRecord<IWidgetCreation> | null;
  setSelectedOptionId: (id: number) => void;
}

export const AdditonalFeedbackSection = ({
  additionalFeedback: additionalFeedbackProp,
  options,
  handleChange,
  setSelectedOptionId,
}: IAdditionalFeedbackSectionProps) => {
  const firstInputField = useRef<HTMLInputElement>(null);
  if (!options) {
    return <Typography variant="body1">You need to add some options first.</Typography>;
  }

  const additionalFeedback: IAdditonalFeedback = additionalFeedbackProp || {
    active: false,
    options: [],
  };

  const copyValuesToAllOptions = ({
    title,
    placeholder,
  }: {
    title: string;
    placeholder: string;
  }) => {
    const newOptions = options?.map(
      (option): IAdditionalFeedbackOption => ({
        optionId: option.id,
        title,
        placeholder,
      }),
    );

    handleChange('additionalFeedback', () => ({
      ...(additionalFeedback || {}),
      options: newOptions,
    }))({ target: { value: null } } as any);
  };

  useEffect(() => {
    if (additionalFeedback?.active && firstInputField.current) {
      firstInputField.current.focus();
    }
  }, [additionalFeedback?.active]);

  return (
    <CardContent>
      <Box mb={2}>
        <Typography gutterBottom variant="body1" color="text.secondary">
          Ask for additional feedback after the user has voted.
        </Typography>
      </Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              onChange={(e) =>
                handleChange('additionalFeedback', (value) => ({
                  ...additionalFeedback,
                  active: value as boolean,
                }))({ target: { value: e.target.checked } } as any)
              }
              checked={additionalFeedback?.active}
            />
          }
          label={'Do you want to ask for additonal feedback?'}
        />
      </FormGroup>
      <FormContainer
        style={
          !additionalFeedback?.active ? { filter: 'brightness(0.5)', pointerEvents: 'none' } : {}
        }
      >
        {options.map((option, index) => {
          const optionFeedback = additionalFeedback.options?.find(
            (feedback) => feedback.optionId === option.id,
          ) || { optionId: option.id, title: '' };

          return (
            <FeedbackInputWrapper key={option.id}>
              <label>Text for option: "{option.name}"</label>
              <StyledTextField
                inputRef={index === 0 ? firstInputField : null}
                type="text"
                value={optionFeedback?.title || ''}
                disabled={!additionalFeedback?.active}
                placeholder="Why did you choose this answer?"
                label="Title"
                onFocus={() => setSelectedOptionId(option.id)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange('additionalFeedback', (value) => {
                    const options = [
                      ...excludeAddionalFeedbackOptionsById(additionalFeedback, option.id),
                      {
                        ...getAdditonalFeedbackOptionById(additionalFeedback, option.id),
                        optionId: option.id,
                        title: value as string,
                      },
                    ];

                    return {
                      ...(additionalFeedback || {}),
                      options,
                    };
                  })(e);
                }}
              />
              <StyledTextField
                type="text"
                value={optionFeedback?.placeholder || ''}
                disabled={!additionalFeedback?.active}
                placeholder="Why did you choose this answer?"
                label="Placeholder"
                onFocus={() => setSelectedOptionId(option.id)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange('additionalFeedback', (value) => ({
                    ...(additionalFeedback || {}),
                    options: [
                      ...excludeAddionalFeedbackOptionsById(additionalFeedback, option.id),
                      {
                        ...getAdditonalFeedbackOptionById(additionalFeedback, option.id),
                        optionId: option.id,
                        placeholder: value as string,
                      },
                    ],
                  }))(e);
                }}
              />
              {index === 0 && (
                <Button
                  onClick={() =>
                    copyValuesToAllOptions({
                      title: optionFeedback?.title,
                      placeholder: optionFeedback?.placeholder,
                    })
                  }
                >
                  Copy text to all other options
                </Button>
              )}
            </FeedbackInputWrapper>
          );
        })}
        <FormControlLabel
          control={
            <Switch
              onChange={(e) =>
                handleChange('additionalFeedback', (value) => ({
                  ...additionalFeedback,
                  email: {
                    ...additionalFeedback?.email,
                    active: value as boolean,
                  },
                }))({ target: { value: e.target.checked } } as any)
              }
              checked={additionalFeedback?.email?.active}
            />
          }
          label={'Ask for email?'}
        />
        {additionalFeedback?.email?.active && (
          <FeedbackInputWrapper>
            <StyledTextField
              type="text"
              value={additionalFeedback?.email?.text || ''}
              disabled={!additionalFeedback?.active}
              placeholder="Can I reach out to you for more questions?"
              label="Email title"
              onFocus={() => setSelectedOptionId(1)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleChange('additionalFeedback', (value) => ({
                  ...additionalFeedback,
                  email: {
                    ...additionalFeedback.email,
                    text: value as string,
                  },
                }))(e);
              }}
            />
            <StyledTextField
              type="text"
              value={additionalFeedback?.email?.placeholder || ''}
              disabled={!additionalFeedback?.active}
              placeholder="Your best email"
              label="Email placeholder"
              onFocus={() => setSelectedOptionId(1)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleChange('additionalFeedback', (value) => ({
                  ...additionalFeedback,
                  email: {
                    ...additionalFeedback.email,
                    placeholder: value as string,
                  },
                }))(e);
              }}
            />
          </FeedbackInputWrapper>
        )}
      </FormContainer>
    </CardContent>
  );
};
