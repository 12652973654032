import { BaseEmoji, Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import { styled } from '@mui/material/styles';
import { IOption, IReactionPreset } from '#/dto/IWidget';
import { Reaction } from '../Reaction';

const PickerWrapper = styled('div')`
  margin: ${({ theme }) => theme.spacing(2)} auto;
`;

const OptionsWrapper = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(2)};
`;

interface CustomPickerProps {
  addOption: (option: IOption, presetName?: IReactionPreset) => void;
  removeOption: (id: number) => void;
  options: IOption[];
}

export const CustomPicker = ({ addOption, options, removeOption }: CustomPickerProps) => {
  return (
    <div>
      <OptionsWrapper>
        {options.map((option, index) => (
          <Reaction
            key={`reaction-option-${index}`}
            reaction={option?.name}
            onDelete={() => removeOption(index)}
          />
        ))}
      </OptionsWrapper>
      <PickerWrapper>
        <Picker
          title="Add a reaction"
          set="apple"
          onSelect={(e: BaseEmoji) =>
            addOption({ name: e.native, id: options?.length + 1 }, 'custom')
          }
          theme="dark"
          style={{ width: '100%' }}
        />
      </PickerWrapper>
    </div>
  );
};
