import { stringToEmoji } from '#/helpers/utils';
import { IconButton, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ReactionProps {
  reaction: string;
  onDelete: () => void;
}

const ReactionWrapper = styled('span')`
  position: relative;
  font-size: 40px;
  padding: 4px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  bottom: -20px;
  right: calc(50% - 20px);
  cursor: pointer;
  color: ${({ theme }) => theme.palette.grey[500]};
`;

export const Reaction = ({ reaction, onDelete }: ReactionProps) => {
  return (
    <ReactionWrapper>
      <StyledIconButton onClick={onDelete}>
        <CloseIcon />
      </StyledIconButton>
      {stringToEmoji(reaction)}
    </ReactionWrapper>
  );
};
