import { Component, ErrorInfo } from 'react';
import { Notifier } from '@airbrake/browser';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ErrorBoundaryProps {}

class ErrorBoundary extends Component<ErrorBoundaryProps, { hasError: boolean }> {
  private airbrake: Notifier;
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
    this.airbrake = new Notifier({
      projectId: 386767,
      projectKey: '554349b3c6e31615bf415f5953ccd2a9',
    });
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // Display fallback UI
    this.setState({ hasError: true });
    if (!window.location.href?.includes('localhost:')) {
      // Send error to Airbrake
      this.airbrake.notify({
        error: error,
        params: { info: info },
      });
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
