import { PollOptions } from '../PollOptions';
import { ReactionOptions } from '../ReactionOptions/ReactionOptions';
import { styled, CardContent, Switch, FormGroup, FormControlLabel, Box } from '@mui/material';
import { StyledTextField } from '../PollForm.styles';
import { IOption, IReactionPreset, IWidgetCreation } from '#/dto/IWidget';
import { ErrorRecord } from '#/hooks/useForm';

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexWrap: 'wrap',
});

interface IGeneralSectionProps {
  data: IWidgetCreation;
  handleChange: (
    key: keyof IWidgetCreation,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  removeOption: (index: number) => void;
  addEmptyOption: () => void;
  errors: ErrorRecord<IWidgetCreation> | null;
  addOption: (option: IOption, presetName?: IReactionPreset) => void;
  setOptions: (options: IOption[], presetName?: IReactionPreset) => void;
}

export const GeneralSection = ({
  data,
  handleChange,
  addEmptyOption,
  removeOption,
  errors,
  setOptions,
  addOption,
}: IGeneralSectionProps) => {
  return (
    <>
      <StyledCardContent>
        <StyledTextField
          variant="standard"
          label={data.type === 'poll' ? 'Title*' : 'Title'}
          value={data.title}
          onChange={handleChange('title')}
          error={!!errors?.title}
        />
        <StyledTextField
          variant="standard"
          label="Description"
          value={data.description}
          onChange={handleChange('description')}
          error={!!errors?.description}
        />
        {data.type === 'poll' && (
          <PollOptions
            options={data.options}
            handleChange={handleChange as any}
            removeOption={removeOption}
            addEmptyOption={addEmptyOption}
            widgetType={data.type}
          />
        )}
        <Box sx={{ m: 1 }} />
        <FormGroup style={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Switch
                onChange={(e) =>
                  handleChange('restrictVotesByPath')({
                    target: { value: e.target.checked as any },
                  } as any)
                }
                checked={data.restrictVotesByPath ?? false}
              />
            }
            label={'Can vote once per page'}
          />
        </FormGroup>
        <FormGroup style={{ width: '100%' }}>
          <FormControlLabel
            control={
              <Switch
                onChange={(e) =>
                  handleChange('askForEmail')({
                    target: { value: e.target.checked as any },
                  } as any)
                }
                checked={data.askForEmail ?? false}
              />
            }
            label={'Ask for email'}
          />
        </FormGroup>
        <Box sx={{ m: 2 }} />
        {data.type === 'reaction' && (
          <ReactionOptions
            addOption={addOption}
            options={data.options}
            setOptions={setOptions}
            removeOption={removeOption}
          />
        )}
      </StyledCardContent>
    </>
  );
};
