import routes from '#/constants/routes';
import { IUser } from '#/dto/IUser';
import { hasAccess } from '#/helpers/subscriptionUtils';
import { useAuth0 } from '@auth0/auth0-react';
import { FC } from 'react';
import { RouteProps, Redirect, Route, useHistory } from 'react-router-dom';
import Layout from './Layout';

const NON_PREMIUM_ROUTES = [routes.subscriptionPlans, routes.account, routes.trialFinished];

const PrivateRoute: FC<RouteProps & { user: IUser }> = ({ user, ...props }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const history = useHistory();

  if (user && !hasAccess(user) && !NON_PREMIUM_ROUTES.includes(history.location.pathname)) {
    if (user?.trial) {
      history.push(routes.trialFinished);
    } else if (!user?.currentSubscriptionEnd) {
      history.push(routes.subscriptionPlans);
    } else {
      history.push(routes.account);
    }

    return null;
  }

  return isAuthenticated || isLoading ? (
    <Layout user={user}>
      <Route exact path="/">
        <Redirect to={routes.dashboard} />
      </Route>
      <Route {...props} />
    </Layout>
  ) : (
    <Redirect to={routes.onboarding} />
  );
};

export default PrivateRoute;
