import api from '#/constants/api';
import routes from '#/constants/routes';
import { ISubscriptionResponse } from '#/dto/ISubscriptionResponse';
import { IUser } from '#/dto/IUser';
import { useRequest } from '#/hooks/useRequest';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, capitalize, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const Account = () => {
  const { user: auth0User } = useAuth0();
  const [loadingBilling, setLoadingBilling] = useState(false);
  const [, setLoading] = useState(false);
  const [user, setUser] = useState<IUser>();
  const request = useRequest();
  const handleManageBilling = async () => {
    setLoadingBilling(true);
    const { res } = await request<ISubscriptionResponse>(api.manageSubscription, {
      method: 'POST',
    });
    setLoadingBilling(false);
    if (res?.data?.redirectUrl) {
      window.location.href = res.data.redirectUrl;
    }
  };

  const loadUser = async () => {
    setLoading(true);
    const { res } = await request<IUser>(api.me);
    setLoading(false);
    if (res?.data) {
      setUser(res.data);
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  let subscriptionEndText =
    ['active'].includes(user?.subscriptionStatus) && !user?.cancelAt
      ? 'Next billing date'
      : 'Active until';
  if (user?.trial) {
    subscriptionEndText = 'Trial ends';
  }

  const verifiedText =
    (user?.email === auth0User?.email &&
      (auth0User?.email_verified ? '(verified)' : ' (not verified)')) ||
    '';

  return (
    <>
      <Typography gutterBottom variant="h1">
        Account
      </Typography>
      <Typography gutterBottom variant="h2">
        Personal Information
      </Typography>
      <Typography gutterBottom variant="body1">
        Email: {user?.email} {verifiedText}
      </Typography>
      <Typography gutterBottom variant="h2" sx={{ mt: 2 }}>
        Billing
      </Typography>
      <Typography gutterBottom variant="body1">
        Subscription:{' '}
        {user?.trial
          ? 'Trial'
          : user?.cancelAt
          ? 'Canceled'
          : capitalize(user?.subscriptionStatus || '')}
      </Typography>
      <Typography gutterBottom variant="body1">
        {subscriptionEndText}: {dayjs(user?.currentSubscriptionEnd).format('L')}
      </Typography>
      <Box sx={{ mt: 2 }}>
        {user?.trial ? (
          <Button
            component={Link}
            to={routes.subscriptionPlans}
            variant="contained"
            color="primary"
          >
            See subscription plan
          </Button>
        ) : (
          <Button onClick={handleManageBilling} variant="contained" color="primary">
            {loadingBilling ? 'Loading...' : 'Manage billing'}
          </Button>
        )}
      </Box>
    </>
  );
};
