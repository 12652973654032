import routes from '#/constants/routes';
import { IGlobalAnalytics } from '#/dto/IWidgetAnalytics';
import { Button, Card, CardContent, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface SummaryProps {
  className?: string;
  analytics: IGlobalAnalytics;
  loading: boolean;
}

const TitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
}));

const SummariesWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
}));

const SummaryCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  flex: '1 0 100%',
}));

export const Summary = ({ className, analytics, loading }: SummaryProps) => {
  return (
    <Card className={className}>
      <CardContent>
        <TitleWrapper>
          <Typography variant="h2">Summary</Typography>
          <Button variant="contained" component={Link} to={routes.createWidget}>
            + Create widget
          </Button>
        </TitleWrapper>
        <SummariesWrapper>
          <SummaryCard>
            <CardContent>
              <Typography variant="h6">Total widgets</Typography>
              <Typography variant="h4">{loading ? '...' : analytics?.widgetCount}</Typography>
            </CardContent>
          </SummaryCard>
          <SummaryCard>
            <CardContent>
              <Typography variant="h6">Total responses</Typography>
              <Typography variant="h4">{loading ? '...' : analytics?.voteCount}</Typography>
            </CardContent>
          </SummaryCard>
        </SummariesWrapper>
      </CardContent>
    </Card>
  );
};
