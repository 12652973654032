import { EmbedCode } from '#/components/EmbedCode';
import { AllResponses } from '#/components/Responses/AllResponses';
import api from '#/constants/api';
import routes from '#/constants/routes';
import { IWidget } from '#/dto/IWidget';
import { useRequest } from '#/hooks/useRequest';
import { getWidgetUrl } from '#/helpers/utils';
import {
  Button,
  Link as MuiLink,
  LinearProgress,
  styled,
  Typography,
  Grid,
  Modal,
  Box,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { PageAnalytics } from './PageAnalytics';
import { PeriodSummaries } from './PeriodSummaries/PeriodSummaries';
import { VotesChart } from './VotesChart/VotesChart';

const AnalyticsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const Header = styled('span')({
  display: 'flex',
  justifyContent: 'space-between',
});

const EmbedModalWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: theme.spacing(2),
}));

interface WidgetParams {
  id?: string;
}

type WidgetAnalyticsProps = RouteComponentProps<WidgetParams>;

export const WidgetAnalytics = ({
  match: {
    params: { id },
  },
}: WidgetAnalyticsProps) => {
  const [showEmbedModal, setShowEmbedModal] = useState(false);
  const [widget, setWidget] = useState<IWidget>();
  const [isLoading, setIsLoading] = useState(false);
  const widgetUrl = useMemo(() => getWidgetUrl(id), [id]);
  const request = useRequest();
  const requestWidget = useCallback(
    async (widgetId: string) => {
      setIsLoading(true);
      const { res } = await request<IWidget>(api.widget(widgetId));
      setIsLoading(false);
      if (res?.data) {
        setWidget(res.data);
      }
    },
    [id],
  );

  useEffect(() => {
    requestWidget(id);
  }, [id, requestWidget]);

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!widget) return null;

  return (
    <>
      <AnalyticsWrapper>
        <Header>
          <div>
            <Typography variant="body1" color="text.secondary">
              ANALYTICS
            </Typography>
            <Typography variant="h1">{widget.internalTitle || widget.title}</Typography>
          </div>
          <Grid sx={{ m: 'auto 0', width: 'fit-content' }} gap={2} container>
            <Button to={routes.editWidget(id)} component={Link} type="button" variant="outlined">
              Edit
            </Button>
            <Button onClick={() => setShowEmbedModal(true)} variant="contained" type="button">
              Embed
            </Button>
          </Grid>
        </Header>
        <Typography variant="body1">
          <MuiLink target="_blank" rel="noopener noreferrer" href={widgetUrl}>
            {widgetUrl}
          </MuiLink>
        </Typography>
        <PeriodSummaries widget={widget} />
        <VotesChart isLoading={isLoading} widget={widget} />
        <AllResponses widget={widget} />
        <PageAnalytics widget={widget} />
      </AnalyticsWrapper>
      <Modal open={showEmbedModal} onClose={() => setShowEmbedModal(false)}>
        <EmbedModalWrapper>
          <EmbedCode widget={widget} />
        </EmbedModalWrapper>
      </Modal>
    </>
  );
};
