import { useEffect, useState } from 'react';
import { useRequest } from '../hooks/useRequest';
import api from '../constants/api';
import { IUser } from '../dto/IUser';
import { useAuth0 } from '@auth0/auth0-react';
import { getConsentLocalStorage, removeConsentLocalStorage } from '#/helpers/localConsent';
import routes from '#/constants/routes';
import { useHistory } from 'react-router-dom';

export const useAuthentication = () => {
  const [loadingUser, setLoadingUser] = useState(false);
  const [user, setUser] = useState<IUser>(null);
  const { isAuthenticated, isLoading, user: auth0User } = useAuth0();
  const request = useRequest();
  const history = useHistory();

  const getOrCreateUser = async () => {
    if (!auth0User?.email_verified) {
      history.push(routes.validateEmail);
      return;
    }

    setLoadingUser(true);
    const sessionId = new URLSearchParams(window.location.search).get('session_id');
    const marketingConsent = getConsentLocalStorage();
    const response = await request<IUser>(
      `${api.getCreateUser}?marketingConsent=${marketingConsent}`,
    );
    setLoadingUser(false);
    if (response.res?.data) {
      setUser(response.res.data);

      if (sessionId) {
        history.push(routes.dashboard);
      }
    }
    removeConsentLocalStorage();

    if (response.res?.status === 201) {
      history.push(routes.createWidget);
      return;
    }
  };

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      getOrCreateUser();
    }
  }, [isAuthenticated, isLoading]);

  return {
    loadingUser,
    user,
    isLoading,
  };
};
