import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material';
import { red } from '@mui/material/colors';
import { jssPreset, StylesProvider } from '@mui/styles';
import { create, Jss } from 'jss';
import { FC } from 'react';

const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;',
    },
    palette: {
      primary: {
        main: '#0D3B66',
      },
      secondary: {
        main: '#FFCF56',
      },
      error: {
        main: red.A400,
      },
      background: {
        default: '#EBEBEB',
      },
    },
  }),
);

const darkTheme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;',
    },
    palette: {
      mode: 'dark',
      primary: {
        main: '#0665c4',
      },
      secondary: {
        main: '#fed356',
      },
      error: {
        main: red.A400,
      },
      background: {
        default: '#202020',
        paper: '#343434',
      },
    },
  }),
);

const jss = create({
  ...jssPreset(),
});

export const CustomTheme: FC<{ customJss?: Jss; dark?: boolean }> = ({ children, dark }) => {
  return (
    <StylesProvider jss={jss} sheetsManager={new Map()}>
      <ThemeProvider theme={dark ? darkTheme : theme}>{children}</ThemeProvider>
    </StylesProvider>
  );
};
