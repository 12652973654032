import { IAdditonalFeedback, IOption, WidgetType } from '#/dto/IWidget';
import { stringToEmoji } from '#/helpers/utils';
import { CardContent, Typography, Button } from '@mui/material';
import { ExtendedFeedback } from './ExtendedFeedback';
import {
  ButtonWrapper,
  CTAWrapper,
  Description,
  OptionWrapper,
  PollWrapper,
  ReactionWrapper,
  StyledIconButton,
  Title,
} from './Poll.styles';
import { PollOption } from './PollOption/PollOption';

export type Steps = 'GENERAL' | 'ADDITIONAL_FEEDBACK' | 'EMAIL' | 'THANKS';

interface PollProps {
  title: string;
  description?: string;
  options?: IOption[];
  type: WidgetType;
  onClick?: (optionId: number) => void;
  selectedOptionId: number | null;
  handleSubmit: (id?: number) => void;
  handleAdditionalFeedbackSubmit?: (additionalFeedback: string, email: string) => void;
  error?: string;
  optionText: string;
  setOptionText: (text: string) => void;
  additionalFeedback?: IAdditonalFeedback;
  step?: Steps;
}

export const Poll = ({
  title,
  description,
  options,
  type,
  onClick,
  selectedOptionId,
  handleSubmit,
  error,
  optionText,
  setOptionText,
  additionalFeedback,
  step,
  handleAdditionalFeedbackSubmit,
}: PollProps) => {
  const handleOptionClick = (id: number) => {
    if (type === 'reaction') {
      handleSubmit(id);
    } else {
      onClick(id);
    }

    if (id !== selectedOptionId) {
      setOptionText('');
    }
  };

  if (step === 'ADDITIONAL_FEEDBACK' && additionalFeedback) {
    return (
      <PollWrapper>
        <CardContent>
          <ExtendedFeedback
            selectedOptionId={selectedOptionId}
            additionalFeedback={additionalFeedback}
            handleSubmit={handleAdditionalFeedbackSubmit}
          />
        </CardContent>
      </PollWrapper>
    );
  }

  return (
    <PollWrapper>
      <CardContent style={{ opacity: step === 'THANKS' ? 0.7 : 1 }}>
        <Title gutterBottom variant="h5">
          {title}
        </Title>
        {description && (
          <Description gutterBottom color="text.secondary" variant="h6">
            {description}
          </Description>
        )}
        <OptionWrapper>
          {type === 'poll' && (
            <>
              {options?.map((option, index) => (
                <PollOption
                  key={index}
                  option={option}
                  handleOptionClick={handleOptionClick}
                  selected={selectedOptionId === option.id}
                  optionText={optionText}
                  setOptionText={setOptionText}
                  hasVoted={step === 'THANKS'}
                />
              ))}
              <CTAWrapper>
                {step === 'THANKS' ? (
                  <Typography variant="h6" textAlign="center">
                    Thanks for voting.
                  </Typography>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => handleSubmit()}
                    disabled={
                      !selectedOptionId ||
                      (options?.find((option) => option.id === selectedOptionId)?.showTextInput &&
                        !optionText)
                    }
                  >
                    Submit
                  </Button>
                )}
              </CTAWrapper>
            </>
          )}

          {type === 'reaction' && (
            <>
              <ReactionWrapper>
                {options?.map((option) => (
                  <ButtonWrapper
                    isSelected={selectedOptionId === option.id}
                    key={`poll-option-${option.id}`}
                  >
                    <StyledIconButton
                      disabled={step === 'THANKS'}
                      onClick={() => handleOptionClick(option.id)}
                    >
                      {stringToEmoji(option.name)}
                    </StyledIconButton>
                  </ButtonWrapper>
                ))}
              </ReactionWrapper>
              {step === 'THANKS' && (
                <Typography variant="h6" textAlign="center">
                  Thanks for voting.
                </Typography>
              )}
            </>
          )}
          {error && (
            <Typography variant="body1" textAlign="center" color="error">
              {error}
            </Typography>
          )}
        </OptionWrapper>
      </CardContent>
    </PollWrapper>
  );
};
