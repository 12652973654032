import { styled } from '@mui/material';

// https://github.com/anthonyjgrove/react-google-login/blob/master/src/google-login.js
const activeStyle = {
  cursor: 'pointer',
  backgroundColor: '#eee',
  color: 'rgba(0, 0, 0, .54)',
  opacity: 1,
};

const hoverStyle = {
  cursor: 'pointer',
  opacity: 0.9,
};

const Icon = styled('img')({
  marginRight: '10px',
  borderRadius: '2px',
});

const GoogleButton = styled('button')(() => ({
  backgroundColor: '#fff',
  padding: '10px',
  display: 'inline-flex',
  alignItems: 'center',
  color: 'rgba(0, 0, 0, .54)',
  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, .24), 0 0 1px 0 rgba(0, 0, 0, .24)',
  borderRadius: 2,
  border: '1px solid transparent',
  fontSize: 14,
  fontWeight: '500',
  fontFamily: 'Roboto, sans-serif',
  ...activeStyle,
  '&:hover': hoverStyle,
}));

interface GoogleLoginProps {
  handleClick: () => void;
}

export const GoogleLogin = ({ handleClick }: GoogleLoginProps) => {
  return (
    <GoogleButton onClick={handleClick}>
      <Icon height="18px" width="18px" src="/google-logo.svg" />
      <span
        style={{
          fontWeight: 500,
        }}
      >
        Sign up with Google
      </span>
    </GoogleButton>
  );
};
