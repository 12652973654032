import { Button, styled, StandardTextFieldProps, TextField } from '@mui/material';

export const RemoveButton = styled(Button)(() => ({
  flex: '0 0 20%',
}));

export const AddOption = styled(Button)({
  flex: '1 0 100%',
});

export const OptionWrapper = styled('div')(({ theme }) => ({
  flex: '1 0 100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
}));

export const StyledTextField = styled(TextField)<StandardTextFieldProps>(({ theme }) => ({
  flex: '1 0 100%',
  width: '100%',
  margin: 'auto',
  marginBottom: theme.spacing(2),
}));
