import { styled } from '@mui/material';
import { WidgetListItem } from './WidgetListItem';
import { IWidget } from '#/dto/IWidget';

const WidgetListWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, 250px)',
  marginTop: theme.spacing(2),
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
}));

const StyledItem = styled(WidgetListItem)(({ theme }) => ({
  flex: '0 1 auto',
  [theme.breakpoints.down('md')]: {
    flex: '1 0 100%',
  },
}));

export const WidgetList = ({ widgets }: { widgets: IWidget[] }) => {
  return (
    <WidgetListWrapper>
      {widgets.map((widget) => (
        <StyledItem key={widget.shortId} widget={widget} />
      ))}
      <StyledItem isEmpty />
    </WidgetListWrapper>
  );
};
