import routes from '#/constants/routes';
import { DRAWER_WIDTH } from '#/constants/style';
import AddIcon from '@mui/icons-material/Add';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HelpIcon from '@mui/icons-material/Help';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ChatIcon from '@mui/icons-material/Chat';
import PersonIcon from '@mui/icons-material/Person';
import {
  useTheme,
  Divider,
  List,
  ListItemIcon,
  Hidden,
  Drawer,
  ListItemText,
  ListItem,
  SwipeableDrawer,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { IUser } from '#/dto/IUser';
import { AdminSelectUsers } from './AdminSelectUsers';

const HELP_LINK = 'mailto:me@felixgerschau.com?subject=PollPilot Support:';

const StyledDrawer = styled('nav')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
}));

const useStyles = makeStyles(() => ({
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
}));

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

interface Props {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  user?: IUser;
}

const Sidebar: FC<Props> = ({ mobileOpen, handleDrawerToggle, user: currentUser }) => {
  const { logout, isAuthenticated: isAuthenticatedAuth0, user, loginWithPopup } = useAuth0();
  const isAuthenticated = !!currentUser && isAuthenticatedAuth0;
  const theme = useTheme();
  const history = useHistory();
  const styles = useStyles();
  const handleLogout = useCallback(() => {
    logout({ returnTo: window.location.origin });
  }, []);

  const openPathAndCloseDrawer = (path: string) => {
    history.push(path);
    handleDrawerToggle();
  };

  const drawer = (
    <>
      <StyledLink style={{ margin: '0 auto', padding: '10px 0' }} to="/">
        <Toolbar style={{ padding: 0 }}>
          <img
            height="50px"
            width="50px"
            style={{ marginRight: '10px' }}
            src="/pollpilot-logo-dark.svg"
          />
          <Typography variant="h2">PollPilot</Typography>
        </Toolbar>
      </StyledLink>
      {isAuthenticated ? (
        <>
          <Divider />
          <AdminSelectUsers currentUser={currentUser} />
          <List>
            <Box mb={1}>
              <Typography gutterBottom variant="body1" textAlign="center" fontWeight="bold">
                Welcome{user?.given_name ? `, ${user.given_name}` : ''}!{' '}
              </Typography>
            </Box>
            <ListItem
              button
              selected={history.location.pathname.includes('dashboard')}
              onClick={() => openPathAndCloseDrawer('/dashboard')}
            >
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem
              button
              selected={history.location.pathname.includes(routes.responses)}
              onClick={() => openPathAndCloseDrawer(routes.responses)}
            >
              <ListItemIcon>
                <ChatIcon />
              </ListItemIcon>
              <ListItemText primary="All responses" />
            </ListItem>
            <ListItem
              selected={history.location.pathname.includes(routes.createWidget)}
              onClick={() => openPathAndCloseDrawer(routes.createWidget)}
              button
            >
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Create widget" />
            </ListItem>
          </List>
        </>
      ) : null}
      <Divider />
      <List sx={{ mt: 'auto' }}>
        {isAuthenticatedAuth0 ? (
          <>
            <ListItem onClick={() => window.open(HELP_LINK, '_blank')} button>
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText primary="Help" />
            </ListItem>
            <ListItem
              selected={history.location.pathname.includes(routes.account)}
              onClick={() => openPathAndCloseDrawer(routes.account)}
              button
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="Account" />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItem>
          </>
        ) : (
          <ListItem button onClick={() => loginWithPopup()}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Log in" />
          </ListItem>
        )}
      </List>
    </>
  );

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledDrawer aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="js">
        <SwipeableDrawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          onOpen={() => handleDrawerToggle()}
          classes={{
            paper: styles.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </SwipeableDrawer>
      </Hidden>
      {!isMobile && (
        <Drawer
          classes={{
            paper: styles.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      )}
    </StyledDrawer>
  );
};

export default Sidebar;
