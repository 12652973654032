import { AnalyticsSummary } from '#/components/AnalyticsSummary';
import { IWidget } from '#/dto/IWidget';
import { Card, CardContent, styled, Typography } from '@mui/material';

const StyledCardContent = styled(CardContent)(({}) => ({}));

const SummariesContainer = styled('div')(({ theme }) => ({
  gap: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-around',
  flexWrap: 'wrap',
  marginBottom: theme.spacing(2),
}));

interface PeriodSummariesProps {
  widget: IWidget;
}

export const PeriodSummaries = ({ widget }: PeriodSummariesProps) => {
  return (
    <Card>
      <StyledCardContent>
        <Typography variant="h2" gutterBottom>
          Summary
        </Typography>
        <SummariesContainer>
          <AnalyticsSummary period={null} widget={widget} />
          <AnalyticsSummary period="month" widget={widget} />
          <AnalyticsSummary period="week" widget={widget} />
        </SummariesContainer>
      </StyledCardContent>
    </Card>
  );
};
