import { WidgetType } from '#/dto/IWidget';

export default {
  dashboard: '/dashboard',
  createWidget: '/widget/create',
  createWidgetType: '/widget/create/type',
  createWidgetTypeSelected: (type: WidgetType | ':selectedType' = ':selectedType') =>
    `/widget/create/${type}`,
  editWidget: (id: string) => `/widget/${id}/edit`,
  widget: (id: string) => `/widget/${id}`,
  responses: '/responses',
  auth: '/auth',
  onboarding: '/start',
  account: '/account',
  subscriptionPlans: '/subscribe',
  validateEmail: '/validate-email',
  trialFinished: '/trial-finished',
};
