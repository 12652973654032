import api from '#/constants/api';
import { IWidget } from '#/dto/IWidget';
import { IWidgetAnalytics } from '#/dto/IWidgetAnalytics';
import { useRequest } from '#/hooks/useRequest';
import { getDateRange } from '#/helpers/dateUtils';
import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  styled,
  useTheme,
  Grid,
} from '@mui/material';
import { ChartData, ChartOptions } from 'chart.js';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';

const StyledCardContent = styled(CardContent)(({}) => ({}));

const TitleWrapper = styled('div')(() => ({
  flex: '1 0 100%',
}));

const ChartsWrapper = styled('div')(({ theme }) => ({
  gridTemplateColumns: 'repeat(auto-fill, 500px)',
  display: 'grid',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(auto-fill, 250px)',
  },
}));

const ChartWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  height: '300px',
  [theme.breakpoints.down('sm')]: {
    height: '200px',
  },
}));

const StyledLine = styled(Line)(({}) => ({}));

interface VotesChartProps {
  widget: IWidget;
  isLoading: boolean;
}

export const VotesChart = ({ widget, isLoading }: VotesChartProps) => {
  const theme = useTheme();
  const [chartData, setChartData] = useState<IWidgetAnalytics>();
  const [isLoadingAnalytics, setIsLoadingAnalytics] = useState(false);
  const request = useRequest();

  const loadAnalytics = async (shortId: string) => {
    setIsLoadingAnalytics(true);
    const response = await request<IWidgetAnalytics>(
      `${api.widgetAnalytics(shortId)}?historicalResponseData=true`,
    );
    setIsLoadingAnalytics(false);
    if (response.res?.data) {
      setChartData(response.res?.data);
    }
  };

  useEffect(() => {
    if (widget && widget?.shortId) {
      loadAnalytics(widget?.shortId);
    }
  }, [widget?.shortId]);

  const now = dayjs();
  const historicalResponseData = chartData?.historicalResponseData || [];
  const dateRange = getDateRange(now.subtract(30, 'days'), now);
  const widgetOptionsSum = widget?.options
    ?.map((option) => option.id)
    ?.reduce((prev, current) => prev + current);
  const widgetOptionsAverage = widgetOptionsSum / (widget?.options?.length || 1);
  const resultDataSet = useMemo(
    () =>
      dateRange.map(
        (date, index) =>
          historicalResponseData.find((response) => response._id === date.format('YYYY-MM-DD'))
            ?.average ?? (index === 0 ? widgetOptionsAverage : undefined),
      ),
    [dateRange, historicalResponseData, widget],
  );

  const resultLineData = useMemo<ChartData<'line'>>(
    () =>
      ({
        labels: dateRange.map((date) => date.format('YYYY-MM-DD')),
        datasets: [
          {
            tension: 0.4,
            data: resultDataSet,
            pointRadius: resultDataSet?.map((value, index) =>
              index === 0 && value === widgetOptionsAverage ? 0 : 3,
            ),
            backgroundColor: theme.palette.background.paper,
            borderColor: theme.palette.primary.main,
            borderWidth: 2,
          },
        ],
      } as ChartData<'line'>),
    [widget, resultDataSet, theme],
  );

  const lineData = useMemo<ChartData<'line'>>(
    () =>
      ({
        labels: dateRange.map((date) => date.format('YYYY-MM-DD')),
        datasets: [
          {
            label: 'Votes',
            data:
              dateRange.map(
                (date) =>
                  historicalResponseData.find(
                    (response) => response._id === date.format('YYYY-MM-DD'),
                  )?.count,
              ) || [],
            backgroundColor: theme.palette.background.paper,
            borderColor: theme.palette.primary.main,
          },
        ],
      } as ChartData<'line'>),
    [widget, historicalResponseData],
  );

  const resultLineOptions: ChartOptions<'line'> = useMemo(
    () => ({
      color: theme.palette.text.primary,
      elements: {
        point: {
          radius: 3,
        },
        line: {
          borderDash: [8, 8],
          borderWidth: 10,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      scales: {
        xAxes: {
          ticks: {
            stepSize: 1,
            color: theme.palette.text.primary,
          },
        },
        y: {
          reverse: ['like-dislike', 'like-neutral-dislike'].includes(widget?.reactionPreset),
          ticks: {
            sampleSize: widget?.options?.length,
            color: theme.palette.text.primary,
            callback: (value: number) =>
              widget?.options?.find((option) => option.id === value)?.name,
          },
        },
      },
      datasets: {
        line: {
          spanGaps: true,
          showLine: true,
        },
      },
    }),
    [widget, theme],
  );

  const barData = useMemo<ChartData<'bar'>>(
    () => ({
      labels: widget?.options.map((option) => option.name?.substring(0, 10)),
      datasets: [
        {
          label: 'Votes',
          data: widget?.options.map(
            (option) => chartData?.results.find((res) => res.optionId === option.id)?.count || 0,
          ),
          backgroundColor: theme.palette.background.paper,
          borderColor: theme.palette.primary.main,
          borderWidth: 2,
        },
      ],
    }),
    [widget, theme, chartData],
  );

  const barOptions: ChartOptions<'bar'> = {
    indexAxis: 'y',
    color: theme.palette.text.primary,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      xAxes: {
        ticks: {
          stepSize: 1,
          color: theme.palette.text.primary,
        },
      },
      yAxes: {
        ticks: {
          color: theme.palette.text.primary,
        },
      },
    },
  };

  const lineOptions: ChartOptions<'line'> = useMemo(
    () => ({
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        yAxes: {
          ticks: {
            stepSize: 1,
            color: theme.palette.text.primary,
          },
        },
        xAxes: {
          ticks: {
            color: theme.palette.text.primary,
          },
        },
      },
      datasets: {
        line: {
          spanGaps: true,
        },
      },
    }),
    [widget, theme],
  );

  const content = (
    <>
      {chartData?.voteCount < 1 ? (
        <Typography variant="body1">No data available.</Typography>
      ) : (
        <>
          <TitleWrapper>
            <Typography variant="body2" color="text.secondary">
              ALL TIME RESULTS
            </Typography>
            <Typography variant="h2" gutterBottom>
              {chartData?.voteCount} votes
            </Typography>
          </TitleWrapper>
          <ChartsWrapper>
            <ChartWrapper>
              <Typography variant="body1" align="center" gutterBottom>
                {widget?.type === 'reaction' ? 'Average results per day' : 'Results'}
              </Typography>
              <div>
                {widget?.type === 'reaction' ? (
                  <StyledLine data={resultLineData} options={resultLineOptions} />
                ) : (
                  <Bar data={barData} options={barOptions} />
                )}
              </div>
            </ChartWrapper>
            <ChartWrapper>
              <Typography align="center" variant="body1" gutterBottom>
                Responses during the last 30 days
              </Typography>
              <div>
                <StyledLine data={lineData} options={lineOptions} />
              </div>
            </ChartWrapper>
          </ChartsWrapper>
        </>
      )}
    </>
  );

  return (
    <Card>
      <StyledCardContent>
        {isLoading || isLoadingAnalytics ? (
          <Grid sx={{ height: '300px' }} container>
            <Grid item sx={{ m: 'auto' }}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          content
        )}
      </StyledCardContent>
    </Card>
  );
};
