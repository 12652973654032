import React from 'react';
import { styled, Card, Typography, RadioGroup, IconButton, lighten } from '@mui/material';

export const PollWrapper = styled(Card)`
  position: relative;
  transition: all 0.3s ease-in-out;
`;

export const OptionWrapper = styled(RadioGroup)({
  display: 'flex',
  flexWrap: 'wrap',
});

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: '40px',
  height: '40px',
  color: 'rgba(0, 0, 0, 1)',
  [theme.breakpoints.up('lg')]: {
    width: '60px',
    height: '60px',
  },
}));

export const ButtonWrapper = styled(
  ({
    isSelected,
    children,
    ...rest
  }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    isSelected?: boolean;
  }) => <div {...rest}>{children}</div>,
)<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  border: isSelected ? `2px solid ${theme.palette.primary.main}` : '2px solid rgba(0, 0, 0, 0)',
  backgroundColor: isSelected ? lighten(theme.palette.primary.light, 0.8) : 'inherit',
  borderRadius: '50%',
  boxSizing: 'border-box',
}));

export const ReactionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  overflowX: 'scroll',
  [theme.breakpoints.up('xs')]: {
    gap: theme.spacing(1),
  },
}));

export const Title = styled(Typography)({
  textAlign: 'center',
});

export const Description = styled(Typography)({
  textAlign: 'center',
});

export const CTAWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));
