import { Grid, styled } from '@mui/material';
import { ReactNode } from 'react';

const Container = styled(Grid)(({ theme }) => ({
  alignItems: 'stretch',
  height: '100vh',
  overflow: 'hidden',
  flexWrap: 'nowrap',
  gap: theme.spacing(2),
}));

const Sidebar = styled('div')`
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.palette.primaryDark[800]};
  height: 100%;
  padding: ${({ theme }) => theme.spacing(3)};
`;

interface OnboardingLayoutProps {
  children: ReactNode;
  sidebar: ReactNode;
}

export const OnboardingLayout = ({ sidebar, children }: OnboardingLayoutProps) => {
  return (
    <Container container>
      <Grid item sx={{ display: { xs: 'none', sm: 'flex', md: 'flex' } }} xs={4}>
        <Sidebar>
          <Grid container sx={{ margin: 'auto', gap: 2 }}>
            {sidebar}
          </Grid>
        </Sidebar>
      </Grid>
      <Grid xs={12} md={8} sx={{ width: '100%', padding: 2 }} item container>
        {children}
      </Grid>
    </Container>
  );
};
