import routes from '#/constants/routes';
import { IUser } from '#/dto/IUser';
import { hasAccess } from '#/helpers/subscriptionUtils';
import { Box, Link as MuiLink, Card, CardContent, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

const BannerCard = styled(Card)<{ warning?: boolean }>`
  width: 100%;
  background-color: ${({ theme, warning }) =>
    warning ? theme.palette.warning[500] : theme.palette.error[500]};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 0;
`;

export const AccessBanner = ({ user }: { user: IUser }) => {
  const daysLeft = dayjs(user?.currentSubscriptionEnd).diff(dayjs(), 'day');
  const trialExpiresSoon = user?.trial && user?.currentSubscriptionEnd && daysLeft < 4;
  const daysLeftSubscription = dayjs(user?.cancelAt).diff(dayjs(), 'day');
  const subscriptionExpiresSoon = !!user?.cancelAt && daysLeftSubscription < 4;
  const hasUserAccess = hasAccess(user);
  if (!user || (hasUserAccess && !trialExpiresSoon && !subscriptionExpiresSoon)) return null;

  let text = user?.trial
    ? 'Your trial has expired.'
    : 'You do not have access to this content. Please check your billing information under "Account"';
  if (hasUserAccess && (trialExpiresSoon || subscriptionExpiresSoon)) {
    text = user?.trial
      ? `Your trial will expire in ${daysLeft} days.`
      : `Your subscription will expire in ${daysLeftSubscription} days`;
  }

  return (
    <>
      <Box sx={{ mt: '100px' }} />
      <BannerCard warning={hasUserAccess}>
        <CardContent>
          <Typography variant="h6" color="white" textAlign="center">
            {text}
          </Typography>
          {(trialExpiresSoon || subscriptionExpiresSoon) && hasUserAccess && (
            <Typography variant="body1" color="white" textAlign="center">
              <Link
                component={MuiLink}
                to={user?.trial ? routes.subscriptionPlans : routes.account}
              >
                Click here
              </Link>{' '}
              to renew your subscription.
            </Typography>
          )}
        </CardContent>
      </BannerCard>
    </>
  );
};
