import routes from '#/constants/routes';
import { Route, Switch } from 'react-router-dom';
import { Login } from './Steps/Login';

export const Onboarding = () => {
  return (
    <Switch>
      <Route path={routes.onboarding} component={Login} />
    </Switch>
  );
};
