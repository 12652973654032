import { AnalyticsSummary } from '#/components/AnalyticsSummary';
import api from '#/constants/api';
import { IVoteCount } from '#/dto/IVote';
import { IWidget } from '#/dto/IWidget';
import { useRequest } from '#/hooks/useRequest';
import { Button, Card, CardContent, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface IPageAnalyticsProps {
  widget: IWidget;
}

const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
});

const SummariesContainer = styled('div')(({ theme }) => ({
  gap: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginBottom: theme.spacing(2),
}));

const CenteredButton = styled(Button)(({}) => ({
  margin: 'auto',
}));

export const PageAnalytics = ({ widget }: IPageAnalyticsProps) => {
  const [voteCounts, setVoteCount] = useState<IVoteCount[]>();
  const [showAll, setShowAll] = useState(false);
  const request = useRequest();
  const loadPaths = async (shortId: string) => {
    const { res } = await request<IVoteCount[]>(api.votesCount(shortId, 'path'));
    if (res?.data) {
      setVoteCount(
        res.data?.filter((voteCount) => !!voteCount._id)?.sort((a, b) => b.count - a.count),
      );
    }
  };

  useEffect(() => {
    if (widget?.shortId) {
      loadPaths(widget.shortId);
    }
  }, [widget?.shortId]);

  if (!voteCounts?.length) return null;

  const currentVoteCount = showAll ? voteCounts : voteCounts.slice(0, 3);

  return (
    <Card>
      <StyledCardContent>
        <Typography variant="h2" gutterBottom>
          Page analytics
        </Typography>
        <SummariesContainer>
          {currentVoteCount?.map((count) => (
            <AnalyticsSummary
              key={`pageanalytics-${count._id}`}
              widget={widget}
              path={count._id}
              pageTitle={count.pageTitle}
            />
          ))}
        </SummariesContainer>
        {voteCounts.length > 3 && (
          <CenteredButton variant="outlined" onClick={() => setShowAll(!showAll)}>
            {showAll ? 'Show less' : 'Show more'}
          </CenteredButton>
        )}
      </StyledCardContent>
    </Card>
  );
};
