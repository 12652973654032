import { ChangeEventHandler } from 'react';
import { MIN_OPTION_COUNT } from '../../constants';
import { PollForm } from './PollOptions.types';
import { AddOption, OptionWrapper, RemoveButton, StyledTextField } from '../PollForm.styles';
import { IOption, WidgetType } from '#/dto/IWidget';
import { Checkbox, Tooltip } from '@mui/material';

interface PollOptionsProps<T> {
  options: IOption[];
  removeOption: (index: number) => void;
  addEmptyOption: () => void;
  handleChange: <S extends unknown>(
    key: keyof T,
    sanitizeFn?: (value: string, prevValue?: S) => S,
  ) => ChangeEventHandler<HTMLInputElement>;
  widgetType: WidgetType;
}

export const PollOptions = ({
  options,
  removeOption,
  addEmptyOption,
  handleChange,
  widgetType,
}: PollOptionsProps<PollForm>) => {
  const handleOptionChange = (index: number, field: keyof IOption) => {
    return handleChange('options', (value, prevOptions: IOption[]) => {
      const newValue = [...prevOptions];
      (newValue[index] as Record<typeof field, any>)[field] = value;
      return newValue;
    });
  };

  return (
    <>
      {Array(options.length)
        .fill('')
        .map((_value, index) => {
          const showRemoveButton = index > MIN_OPTION_COUNT - 1;
          const required = index < 2;
          return (
            <OptionWrapper key={`option-wrapper-${index}`}>
              <StyledTextField
                key={'option-' + index}
                variant="standard"
                label="Option"
                value={options[index]?.name || ''}
                required={required}
                style={{
                  flex: '1 0 8%',
                }}
                onChange={handleOptionChange(index, 'name')}
              />
              {widgetType === 'poll' && (
                <Tooltip title="Show text field">
                  <Checkbox
                    onChange={(e) =>
                      handleOptionChange(
                        index,
                        'showTextInput',
                      )({
                        target: { value: e.target.checked as any },
                      } as any)
                    }
                    checked={options[index]?.showTextInput || false}
                  />
                </Tooltip>
              )}
              {showRemoveButton && (
                <RemoveButton key={'remove-option-' + index} onClick={() => removeOption(index)}>
                  Remove
                </RemoveButton>
              )}
            </OptionWrapper>
          );
        })}
      <AddOption onClick={() => addEmptyOption()}>Add option</AddOption>
    </>
  );
};
