import { IUser } from '#/dto/IUser';
import dayjs from 'dayjs';

export const hasAccess = (user: IUser) =>
  user?.currentSubscriptionEnd &&
  dayjs(new Date())
    .subtract(user?.trial ? 1 : 3, 'day')
    .isBefore(new Date(user.currentSubscriptionEnd)) &&
  (user?.trial || ['active'].includes(user?.subscriptionStatus)) &&
  (!user?.cancelAt || dayjs(new Date()).subtract(1, 'days').isBefore(new Date(user?.cancelAt)));
