import { Grid, styled, Typography } from '@mui/material';

const LoadingContainer = styled(Grid)`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

const SecondContainer = styled(Grid)`
  flex-direction: column;
  margin: auto;
`;

const Image = styled('img')`
  margin: auto;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const LoadingScreen = () => {
  return (
    <LoadingContainer container>
      <SecondContainer container>
        <Image height="300px" width="300px" src="/pollpilot-logo-dark.svg" />
        <Typography variant="h4" align="center" color="text.secondary">
          Authenticating
        </Typography>
      </SecondContainer>
    </LoadingContainer>
  );
};
