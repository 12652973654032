import api from '#/constants/api';
import { IWidget, WidgetType } from '#/dto/IWidget';
import { useRequest } from '#/hooks/useRequest';
import { Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { PollForm } from './PollForm';
import LinearProgress from '@mui/material/LinearProgress';

interface WidgetParams {
  id?: string;
}

type WidgetEditorProps = RouteComponentProps<WidgetParams>;

export const WidgetEditor = ({
  match: {
    params: { id },
  },
}: WidgetEditorProps) => {
  const { selectedType: selectedTypeParams } = useParams<{ selectedType: WidgetType }>();
  const [widget, setWidget] = useState<IWidget>(undefined);
  const isEdit = window.location.href?.includes('/edit');
  const [isLoading, setIsLoading] = useState(false);
  const request = useRequest();

  const requestWidget = useCallback(
    async (widgetId: string) => {
      setIsLoading(true);
      const { res } = await request<IWidget>(api.widget(widgetId));
      setIsLoading(false);
      if (res?.data) {
        setWidget(res.data);
      }
    },
    [id],
  );

  useEffect(() => {
    if (id && isEdit) {
      requestWidget(id);
    }
  }, [id]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h1">
          {isEdit
            ? '✍️  Edit your widget'
            : `${selectedTypeParams === 'reaction' ? '😯' : '🗣'} Create a new ${
                selectedTypeParams === 'reaction' ? 'reaction' : 'poll'
              }`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <PollForm type={selectedTypeParams} widget={widget} isEdit={isEdit} />
      </Grid>
    </Grid>
  );
};
