import { Card, CardActionArea, styled, Typography } from '@mui/material';
import { WidgetType } from '#/dto/IWidget';

const StyledCard = styled(Card)`
  width: 300px;
`;

const ActionArea = styled(CardActionArea)`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(2)};
  flex-direction: column;
`;

const TypeTitle = styled(Typography)``;

const getName = (name: WidgetType) => name.charAt(0).toUpperCase() + name.slice(1);

export const Type = ({ name, onClick }: { name: WidgetType; onClick: () => void }) => {
  return (
    <StyledCard>
      <ActionArea onClick={onClick}>
        <TypeTitle textAlign="center" variant="h2">
          {getName(name)}
        </TypeTitle>
        {name === 'poll' && <img height="70px" src="/poll-outline.svg" color="#fff" />}
        {name === 'reaction' && <img height="70px" src="/reaction-outline.svg" />}
        {name === 'poll' ? (
          <>
            <Typography variant="body1">
              Ask your audience for feedback via an embeddable poll.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="body1">
              Understand user sentiment through an embeddable reaction widget.
            </Typography>
          </>
        )}
      </ActionArea>
    </StyledCard>
  );
};
