import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import routes from '../constants/routes';

import { ResponsesPage } from '../pages/ResponsesPage';
import { WidgetAnalytics } from '../pages/WidgetAnalaytics';
import { WidgetEditor } from '../pages/WidgetEditor';
import Dashboard from '../pages/Dashboard';
import { LoadingScreen } from '../components/LoadingScreen';
import { useAuthentication } from './useAuthentication';
import { useAuth0 } from '@auth0/auth0-react';
import { Onboarding } from '#/pages/Onboarding';
import { SelectWigetTypePage } from '#/pages/WidgetEditor/SelectTypePage';
import { Account } from '#/pages/Account';
import { SubscriptionPlans } from '#/pages/SubscriptionPlans';
import { ValidateEmail } from '#/pages/ValidateEmail';
import { TrialFinished } from '#/pages/SubscriptionPages/TrialFinished';

export const Routes = () => {
  const { user, isLoading, loadingUser } = useAuthentication();
  const { isAuthenticated } = useAuth0();
  if (isLoading || loadingUser) {
    return <LoadingScreen />;
  }

  return (
    <Switch>
      <Route path={routes.onboarding}>
        <Onboarding />
      </Route>
      <PrivateRoute user={user} exact path={routes.dashboard} component={Dashboard} />
      <PrivateRoute user={user} path={routes.createWidgetType} component={SelectWigetTypePage} />
      <PrivateRoute
        user={user}
        path={[routes.createWidgetTypeSelected()]}
        component={WidgetEditor}
      />
      <Redirect from={routes.createWidget} to={routes.createWidgetType} />
      <PrivateRoute user={user} path={routes.editWidget(':id')} component={WidgetEditor} exact />
      <PrivateRoute user={user} path={routes.widget(':id')} component={WidgetAnalytics} exact />
      <PrivateRoute user={user} path={routes.responses} component={ResponsesPage} exact />
      <PrivateRoute user={user} path={routes.account} component={Account} exact />
      <PrivateRoute user={user} path={routes.account} component={Account} exact />
      <PrivateRoute
        user={user}
        path={routes.subscriptionPlans}
        component={SubscriptionPlans}
        exact
      />
      <PrivateRoute user={user} path={routes.validateEmail} component={ValidateEmail} exact />
      <PrivateRoute user={user} path={routes.trialFinished} component={TrialFinished} exact />
      <Redirect to={isAuthenticated ? routes.dashboard : routes.onboarding} />
    </Switch>
  );
};
