import api from '#/constants/api';
import { IOption, IReactionPreset, IWidget, IWidgetCreation, WidgetType } from '#/dto/IWidget';
import CheckIcon from '@mui/icons-material/Check';
import { useRequest } from '#/hooks/useRequest';
import { useForm } from '#/hooks/useForm';
import { Poll } from '#/widgets/Poll/Poll';
import {
  styled,
  Grid,
  Card,
  CardContent,
  Tabs,
  Tab,
  CardActions,
  Button,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { MIN_OPTION_COUNT } from '../constants';
import { StyledTextField } from './PollForm.styles';
import { EmbedCode } from '#/components/EmbedCode';
import routes from '#/constants/routes';
import { useHistory } from 'react-router-dom';
import { GeneralSection } from './FormSections/GeneralSection';
import { AdditonalFeedbackSection } from './FormSections/AdditonalFeedbackSection';
import { CustomTheme } from '#/providers/CustomTheme';

const WidgetForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

interface PollFormProps {
  type?: WidgetType;
  isEdit: boolean;
  widget: IWidget;
}

export const PollForm = ({ widget, isEdit, type: selectedTypeParams }: PollFormProps) => {
  const history = useHistory();

  const [optionText, setOptionText] = useState('');
  const [selectedOptionId, setSelectedOptionId] = useState<number>(null);
  const [savedSuccessfully, setSavedSuccessfully] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const request = useRequest();
  const initialValues: IWidgetCreation = useMemo(() => {
    let defaultOptions: IOption[] = [];
    let defaultTitle = '';
    if (selectedTypeParams === 'poll') {
      defaultOptions = Array(MIN_OPTION_COUNT)
        .fill({ id: 1, name: '' })
        .map((_option, index) => ({ id: index + 1, name: '' }));
      defaultTitle = 'What do you want to know?';
    }

    if (selectedTypeParams === 'reaction') {
      defaultOptions = [
        { id: 1, name: '👍' },
        { id: 2, name: '👎' },
      ];
      defaultTitle = 'Did you find this article helpful?';
    }

    return {
      title: defaultTitle,
      options: defaultOptions,
      description: '',
      type: selectedTypeParams === 'reaction' ? 'reaction' : 'poll',
      internalTitle: '',
      restrictVotesByPath: selectedTypeParams === 'reaction',
    };
  }, [selectedTypeParams]);

  const { data, setData, handleChange, handleSubmit, errors } = useForm<IWidgetCreation>({
    validations: {
      title: {
        required: {
          value: (widget?.type || selectedTypeParams) === 'poll',
          message: 'This field is required.',
        },
      },
      description: {},
      options: {},
    },
    initialValues: isEdit ? widget : initialValues,
    onSubmit: async () => {
      const sanitizedData: IWidgetCreation = {
        ...data,
        type: data.type ?? selectedTypeParams,
      };

      setLoading(true);
      setSavedSuccessfully(false);
      let currentError;
      let newWidget: IWidget;
      if (isEdit) {
        const { error, res } = await request<IWidget>(api.widget(widget.shortId), {
          method: 'PUT',
          data: sanitizedData,
        });
        currentError = error;
        newWidget = res?.data;
      } else {
        const { error, res } = await request<IWidget>(api.widgets, {
          method: 'POST',
          data: sanitizedData,
        });

        currentError = error;
        newWidget = res?.data;
      }
      setLoading(false);
      if (!currentError) {
        setSavedSuccessfully(true);

        if (newWidget?.shortId) {
          history.push(routes.widget(newWidget.shortId));
        } else {
          history.push(routes.dashboard);
        }
      }
    },
  });

  const removeOption = (index: number) => {
    const options = data.options.filter((_, i) => i !== index);
    setData((prevData) => ({
      ...prevData,
      options,
    }));
  };

  const addOption = (option: IOption, presetName?: IReactionPreset) => {
    setData((prevData) => ({
      ...prevData,
      options: [...prevData.options, option],
      reactionPreset: presetName,
    }));
  };

  const setOptions = (options: IOption[], presetName?: IReactionPreset) => {
    setData((prevData) => ({
      ...prevData,
      options: options,
      reactionPreset: presetName,
    }));
  };

  const addEmptyOption = () => {
    setData((prevData) => ({
      ...prevData,
      options: [...prevData.options, { id: prevData.options.length + 1, name: '' }],
    }));
  };

  // TODO: Add loading state
  if (!data?.options) return null;

  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <WidgetForm onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <StyledTextField
                variant="standard"
                label="Internal title"
                value={data.internalTitle}
                onChange={handleChange('internalTitle')}
                error={!!errors?.internalTitle}
              />
            </CardContent>
          </Card>
          <Card>
            <Tabs value={selectedTab} onChange={(_e, newIndex) => setSelectedTab(newIndex)}>
              <Tab label="General" />
              <Tab label="Additional feedback" />
            </Tabs>
            {selectedTab === 0 && (
              <GeneralSection
                data={data}
                addEmptyOption={addEmptyOption}
                handleChange={handleChange}
                errors={errors}
                addOption={addOption}
                removeOption={removeOption}
                setOptions={setOptions}
              />
            )}
            {selectedTab === 1 && (
              <AdditonalFeedbackSection
                additionalFeedback={data.additionalFeedback}
                options={data.options}
                handleChange={handleChange}
                errors={errors}
                setSelectedOptionId={setSelectedOptionId}
              />
            )}
            <CardActions>
              <Button variant="contained" type="submit">
                {loading ? 'Saving...' : 'Save'}
              </Button>
              {savedSuccessfully && <CheckIcon style={{ color: 'green', marginLeft: '10px' }} />}
            </CardActions>
          </Card>
        </WidgetForm>
      </Grid>
      <Grid item md={6}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Preview
            </Typography>
            <CustomTheme>
              <Poll
                title={data.title}
                description={data.description}
                options={data.options}
                type={data.type}
                selectedOptionId={selectedOptionId}
                onClick={setSelectedOptionId}
                handleSubmit={() => void 0}
                optionText={optionText}
                setOptionText={setOptionText}
                additionalFeedback={data.additionalFeedback}
                step={
                  data.additionalFeedback?.active && selectedTab === 1
                    ? 'ADDITIONAL_FEEDBACK'
                    : 'GENERAL'
                }
              />
            </CustomTheme>
          </CardContent>
        </Card>
        <br />
        <EmbedCode widget={widget} />
      </Grid>
    </Grid>
  );
};
