import { CustomSnackbarProvider, SnackbarUtilsConfigurator } from './helpers/SnackbarUtils';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'regenerator-runtime/runtime';
import { CssBaseline } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  registerables,
} from 'chart.js';
import ErrorBoundary from './components/ErrorBoundary';
import { NewThemeProvider } from './providers/ThemeContext';
import { Auth0Provider } from '@auth0/auth0-react';
import { Routes } from './Routes';
import { UserContextProvider } from './context/UserContext';
import { BrowserRouter as Router } from 'react-router-dom';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ...registerables);

const App = () => {
  dayjs.extend(localizedFormat);
  dayjs.extend(relativeTime);
  return (
    <ErrorBoundary>
      <UserContextProvider>
        <Auth0Provider
          domain="auth.pollpilot.io"
          clientId="Idnkw28J2fgjdUN3nCD07AJ7olaWKYmt"
          redirectUri={window.location.origin}
          audience="https://api.pollpilot.io"
        >
          <NewThemeProvider>
            <CssBaseline />
            <CustomSnackbarProvider>
              <SnackbarUtilsConfigurator />
              <Router>
                <Routes />
              </Router>
            </CustomSnackbarProvider>
          </NewThemeProvider>
        </Auth0Provider>
      </UserContextProvider>
    </ErrorBoundary>
  );
};

export default App;
