import { FullHeightWrapper } from '#/components/FullHeightWrapper';
import GradientText from '#/components/GradientText/GradientText';
import api from '#/constants/api';
import { ISubscriptionResponse } from '#/dto/ISubscriptionResponse';
import { useRequest } from '#/hooks/useRequest';
import { Box, Button, Card, CardContent, Grid, styled, Switch, Typography } from '@mui/material';
import { useState } from 'react';

const PLAN_MONTHLY = {
  id:
    process.env.NODE_ENV === 'development'
      ? 'price_1Kai1XA9ZV7AyD5eA5YMVyIs'
      : 'price_1KahT5A9ZV7AyD5eB5fbHbLb',
  price: 20,
};
const PLAN_YEARLY = {
  id:
    process.env.NODE_ENV === 'development'
      ? 'price_1KakE7A9ZV7AyD5epLDzoi0x'
      : 'price_1KahT5A9ZV7AyD5e3WpzNq5g',
  price: 16,
};

const SubscriptionCard = styled(Card)`
  background-color: ${({ theme }) => theme.palette.primaryDark[800]};
  margin: auto;
`;

export const SubscriptionPlans = () => {
  const request = useRequest();
  const [loading, setLoading] = useState(false);
  const [yearly, setYearly] = useState(false);
  const selectedPlan = yearly ? PLAN_YEARLY : PLAN_MONTHLY;
  const handleSubscription = async () => {
    const { id: priceId } = yearly ? PLAN_YEARLY : PLAN_MONTHLY;
    setLoading(true);
    const { res } = await request<ISubscriptionResponse>(api.subscribe, {
      method: 'POST',
      data: {
        priceId,
      },
    });
    setLoading(false);

    if (res?.data?.redirectUrl) {
      window.location.href = res.data.redirectUrl;
    }
  };

  return (
    <FullHeightWrapper container>
      <Grid item container sx={{ m: 'auto', flexDirection: 'column' }} gap={4}>
        <Box sx={{ maxWidth: '500px', m: 'auto' }}>
          <Typography textAlign="center" variant="subtitle1" color="primary">
            SIMPLE PRICING
          </Typography>
          <Typography textAlign="center" variant="h1">
            One subscription for <GradientText>all your needs</GradientText>
          </Typography>
        </Box>
        <Grid sx={{ m: 'auto', width: 'fit-content' }} container gap={1}>
          <Grid item sx={{ m: 'auto' }}>
            <Typography variant="body1">Monthly</Typography>
          </Grid>
          <Grid item>
            <Switch checked={yearly} onChange={(e) => setYearly(e.target.checked)} />
          </Grid>
          <Grid item sx={{ m: 'auto' }}>
            <Typography variant="body1">Yearly</Typography>
          </Grid>
        </Grid>
        <SubscriptionCard>
          <CardContent>
            <Box mb={2}>
              <Typography variant="h3" gutterBottom>
                Standard Subscription
              </Typography>
              <Typography textAlign="center" variant="h2">
                ${selectedPlan.price}{' '}
                <Typography component="span" variant="body2">
                  / month
                </Typography>
              </Typography>
            </Box>
            <Box mb={2} textAlign="center">
              <Typography variant="subtitle2">Unlimited widgets</Typography>
              <Typography variant="subtitle2">Unlimited responses</Typography>
              <Typography variant="subtitle2">Customer support</Typography>
              <Typography variant="subtitle2">Cancel anytime</Typography>
            </Box>
            <Grid container>
              <Button variant="contained" sx={{ m: 'auto' }} onClick={handleSubscription}>
                {loading ? 'Loading...' : 'Select plan'}
              </Button>
            </Grid>
          </CardContent>
        </SubscriptionCard>
      </Grid>
    </FullHeightWrapper>
  );
};
