import { FullHeightWrapper } from '#/components/FullHeightWrapper';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Typography } from '@mui/material';

export const ValidateEmail = () => {
  const { user } = useAuth0();
  return (
    <FullHeightWrapper container>
      <Box textAlign="center" sx={{ m: 'auto' }}>
        <Typography gutterBottom variant="h1">
          Please validate your email
        </Typography>
        <Typography variant="h2" color="primary">
          {user?.email}
        </Typography>
      </Box>
    </FullHeightWrapper>
  );
};
