import { IUser } from '#/dto/IUser';
import React from 'react';

interface UserContextProps {
  user: IUser;
  setUser: (user: IUser) => void;
}

const UserContext = React.createContext<UserContextProps>({
  user: null,
  setUser: () => {
    throw new Error('UserContext.setUser() not implemented');
  },
});

export const useUserContext = () => React.useContext(UserContext);
export const UserContextProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState<IUser>(null);

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};
