export const setConsentLocalStorage = (date: string) => {
  localStorage.setItem('consent', date);
};

export const getConsentLocalStorage = () => {
  return localStorage.getItem('consent');
};

export const removeConsentLocalStorage = () => {
  localStorage.removeItem('consent');
};
