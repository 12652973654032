import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

export interface IResponse<T> {
  res?: AxiosResponse<T>;
  error?: AxiosError<any>;
}

export type ApiRequestOptions = AxiosRequestConfig & {
  noAuth?: boolean;
  noErrorHandling?: boolean;
  getAccessToken?: () => Promise<string>;
  external?: boolean;
};

export type RequestFn = <T>(path: string, options?: ApiRequestOptions) => Promise<IResponse<T>>;

export const request: RequestFn = async (path: string, options: ApiRequestOptions = {}) => {
  const url = !options?.external ? process.env.API_URL + path : path;
  const { headers, ...rest } = options;
  try {
    let accessToken = '';
    if (options.getAccessToken) {
      accessToken = await options.getAccessToken();
    }

    const response = await axios({
      url,
      headers: {
        ...(headers || {}),
        ...(options.noAuth ? {} : { Authorization: `Bearer ${accessToken}` }),
      },
      ...rest,
    });

    return {
      res: response,
    };
  } catch (e) {
    if (!options.noErrorHandling) {
      const SnackbarUtils = await import('./SnackbarUtils');
      if (e?.response?.status === 401) {
        SnackbarUtils.default.error('Your session expired');
      } else {
        SnackbarUtils.default.error('Something went wrong.');
      }
    }

    return {
      error: e,
    };
  }
};
