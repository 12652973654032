import dayjs, { Dayjs } from 'dayjs';

export const getDateRange = (startDate: Dayjs, endDate: Dayjs): Dayjs[] => {
  const dates: Dayjs[] = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dates.push(dayjs(currentDate));
    currentDate = currentDate.add(1, 'day');
  }
  return dates;
};
